import React, { useEffect, useState } from 'react';
import './leveldays.css';

const ProgressBar = ({ id, type, height, value, animation }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timeout;
    if (animation.enable) {
      timeout = setTimeout(() => {
        setProgress(value);
      }, animation.delay);
    } else {
      setProgress(value);
    }
    return () => clearTimeout(timeout);
  }, [value, animation]);

  return (
    <div id={id} className={`progress-bar ${type}`} style={{ height }}>
      <div
        className="progress-bar-inner"
        style={{
          width: `${progress}%`,
          transition: animation.enable ? `width ${animation.duration}ms` : 'none'
        }}
      >
        <span className="progress-bar-text">{`${progress}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
