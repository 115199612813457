import React, { useEffect, useRef } from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
import { homeAbout, homeAbout2,  homeAbout3, homeAbout4 } from "../../dummydata"
import im1 from "../../assets/lady.jpg"
import im2 from "../../assets/gentalman.jpg"
import Awrapper from "./Awrapper"

const AboutCard = () => {
  const componentRef = useRef(null);
  useEffect(() => {
    if (componentRef.current) {
componentRef.current.scrollIntoView({ behavior: 'smooth' });
}
}, []);
  return (
    <>
      <section className='aboutHome' ref={componentRef}>
        <div className='container flexSB'>
          <div className='left row'>
            <img src="./images/blog/b3.webp" alt='' />
          </div>
          <div className='right row'>
            <Heading subtitle='LEARN ANYTHING' title='Benefits About Online Learning Expertise' />
            <div className='items'>
              {homeAbout.map((val) => {
                return (
                  <div className='item flexSB'>
                    {/* <div className='img'>
                      <img src={val.cover} alt='' />
                    </div> */}
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='container flexSB container-left'>
          <div className='left row'>

          <Heading subtitle='LEARN ANYTHING' title='Why FluencifyAI Works' />
            <div className='items'>
              {homeAbout2.map((val) => {
                return (
                  <div className='item flexSB'>
                    {/* <div className='img'>
                      <img src={val.cover} alt='' />
                    </div> */}
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
          <div className='right1 row'>
          <img src="./images/blog/b2.webp" alt='' />
          </div>
        </div>

        <div className='container flexSB'>
          <div className='left row'>
            <img src="./images/about.webp" alt='' />
          </div>
          <div className='right row'>
            <Heading subtitle='LEARN ANYTHING' title='Improvement' />
            <div className='items'>
              {homeAbout3.map((val) => {
                return (
                  <div className='item flexSB'>
                    {/* <div className='img'>
                      <img src={val.cover} alt='' />
                    </div> */}
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p >{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className='container flexSB container-left'>
          <div className='left row'>

          <Heading subtitle='LEARN ANYTHING' title='Why FluencifyAI Works' />
            <div className='items'>
              {homeAbout4.map((val) => {
                return (
                  <div className='item flexSB'>
                    {/* <div className='img'>
                      <img src={val.cover} alt='' />
                    </div> */}
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
          <div className='right1 row'>
          <img src="./images/blog/b6.webp" alt='' />
          </div>
        </div>
        <div className='item flexSB'> 
        <div className='text'>
        <h2>Connect with Us</h2>
          <p>Have questions or want to learn more? Feel free to contact us or follow us on social media for updates, tips, and more.</p>
        </div>
        </div>
      </section>
      {/* <Awrapper /> */}
    </>
  )
}

export default AboutCard
