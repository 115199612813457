/**
 * Socket instance connection
 */
import { createContext } from 'react';
import { io, Socket } from 'socket.io-client';

let base = 'https://www.api.fluencifyai.com';
let port = '';

if (process.env.NODE_ENV === 'production') {
  base = 'https://www.api.fluencifyai.com';
  port= '';
}
export const socket: Socket = io(`${base}${port}`);
socket.connect();

export const SocketContext = createContext(socket);