import React, { useEffect, useState } from "react"
import "./signuphome.css"
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
 
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import apiCall from "../../api/Requesthandler";
import { ACTIVATE_USER, REQUEST_TYPE } from "../../api/apiConfig";
import Cookies from 'js-cookie';
import Head from "../common/header/Head";


const Signupcard = () => {
	const navigation = useNavigate();
    // const cookies = new Cookies();
    const { encodedData } = useParams();
  const [userData, setUserData] = useState(null);
	const [formData, setFormData] = useState({
        email: '',
        activationCode: ''
    });
    const [passwordError, setPasswordError] = useState('');

    const handleChange = (e) => {
       
        setFormData({ ...formData, [e.target.name]: e.target.value });
       
        
    };

    useEffect(() => {
        Cookies.set("user", "yesss")
        console.log(encodedData, 'encodedData');
        const decodedData = atob(encodedData);
console.log(decodedData);
    // Parse the decoded JSON data
    const parsedData = JSON.parse(decodedData);
    
        // Set the parsed data to state
        setUserData(parsedData);
        setFormData({ ...formData, ['email']: parsedData.key });
      }, [encodedData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        if (formData.activationCode.length !== 6) {
            toast("Activation code must be exactly 6 digits.");
            // You can reset the input value here if desired
            // event.target.value = "";
            return false;
        }
        
        try {
            // const response = await axios.post('http://localhost:3001/api/users/login', formData);
            // console.log(response, "response.data");
			// if(response.status ==200){
			// 	toast(response.data.message)
            //     localStorage.setItem('username',formData.username)
            //     localStorage.setItem('token',response.data.token)
			// 	setTimeout(() => {
			// 		navigation('/')
			// 	}, 1000);

			// } 

console.log(formData, 'formData');
            apiCall(ACTIVATE_USER.activate,REQUEST_TYPE.POST,formData).then((res)=>{

                console.log(res, '-----------');
                	if(res.status ==200){
				toast(res.response.data.message)
                console.log(res.response.data.token, 'res.response.data.token');
                Cookies.set('access_token',res.response.data.token)
               
                setTimeout(() => {
					navigation('/')
                    window.location.reload();
				}, 1000);


            }
            else {
                console.log(res,'hi');
                console.log(res.response.response);
                toast(res.response.response.data.message)
            }

            })


        } catch (error) {
			console.log(error);
			toast(error.response.data.message);
            console.error('Error submitting form:', error.response.data.message);
            // Handle error, such as displaying an error message to the user
        }
    };

  return (
    <>
	  <Head/>
	<div className="signup-container">
    <h2 className="signup-title">Enter Activation Code sent on your Email..</h2>

        <form onSubmit={handleSubmit} className="signup-form">
            <h2>Enter Activation Code sent on your Email..</h2>
            {/* <div class="form-control">
    <label for="activation-code">Activation Code</label>
    <input type="number" id="activation-code" name="activationCode" minlength="5" maxlength="5" title="Please enter a 5-digit number" onChange={handleChange} required/>

</div> */}
<input type="number" placeholder="your activation code" className="signup-input" name="activationCode" minlength="5" maxlength="5"   onChange={handleChange} required  />


            <button className="signup-button" type="submit">Activate</button>
        </form>
        {/* <div class="login-link">
            <p>Forgot password? <a onClick={()=>{
                console.log('ji')
                navigation("/Recover_password")
             } } >Recover password</a></p>
        </div> */}

    
	</div>
	<ToastContainer/>
    </>
  )
}

export default Signupcard
