// src/DatePickerStyles.js

import { createGlobalStyle } from 'styled-components';

const DatePickerStyles = createGlobalStyle`
  .react-datepicker {
    font-family: 'Arial', sans-serif;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it's on top of other elements */
  }
  .react-datepicker__header {
    background-color: #1eb2a6;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 10px;
  }

  .react-datepicker__current-month {
    color: white;
    font-size: 18px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    font-size: 16px;
    color: #333;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #1eb2a6;
    color: white;
    border-radius: 50%;
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__month-container {
    border-radius: 0 0 12px 12px;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    top: 10px;
    right: 10px;
    border: none;
    background: none;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    border: 1px solid #1eb2a6;
    border-radius: 50%;
    color: #1eb2a6;
  }

  .react-datepicker__navigation--previous {
    left: 10px;
  }

  .react-datepicker__navigation--next {
    right: 10px;
  }
`;

export default DatePickerStyles;
