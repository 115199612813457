import React, { useEffect, useState } from "react"
import AboutCard from "../about/AboutCard"
import Hblog from "./Hblog"
import HAbout from "./HAbout"
import Hero from "./hero/Hero"
import ListTopics from "./listtopic/ListTopics"
import Hprice from "./Hprice"
import Testimonal from "./testimonal/Testimonal"
import Header from "../common/header/Header"
import Footer from "../common/footer/Footer"
import BlogCard from "../blog/BlogCard"
import apiCall from "../../api/Requesthandler"
import { GET_USER, REQUEST_TYPE } from "../../api/apiConfig"
import SessionFrom from "../Sessions/FormContainer"
import Pricing from "../pricing/Pricing"
import Heading from "../common/heading/Heading"
import Categories from "../category/categories"
import Populercourses from "../Popularcourses/Popularcourses"


const Home = () => {
 

  return (
    <>
    
      <Hero />

	  
	  <section className='blog1'>
	
	<Heading subtitle='Categories' title='Categories you want to learn' />
	<Categories/>
  
	</section>
	  <section className='blog'>
	  <Heading subtitle='Book Sessions' title='Find the right tutor for you' />
       
          <BlogCard />
      </section>
	

	  <Pricing />
	
	  {/* <Populercourses/> */}
    {/* <ListTopics/> */}
      {/* <section id="list-topics" className="list-topics">
			<div className="container">
				<div className="list-topics-content">
					<ul>
						<li>
							<div className="single-list-topics-content">
								<div className="single-list-topics-icon">
									<i className="flaticon-restaurant"></i>
								</div>
								<h2><a href="#">resturent</a></h2>
								<p>150 listings</p>
							</div>
						</li>
						<li>
							<div className="single-list-topics-content">
								<div className="single-list-topics-icon">
									<i className="flaticon-travel"></i>
								</div>
								<h2><a href="#">destination</a></h2>
								<p>214 listings</p>
							</div>
						</li>
						<li>
							<div className="single-list-topics-content">
								<div className="single-list-topics-icon">
									<i className="flaticon-building"></i>
								</div>
								<h2><a href="#">hotels</a></h2>
								<p>185 listings</p>
							</div>
						</li>
						<li>
							<div className="single-list-topics-content">
								<div className="single-list-topics-icon">
									<i className="flaticon-pills"></i>
								</div>
								<h2><a href="#">healthcaree</a></h2>
								<p>200 listings</p>
							</div>
						</li>
						<li>
							<div className="single-list-topics-content">
								<div className="single-list-topics-icon">
									<i className="flaticon-transport"></i>
								</div>
								<h2><a href="#">automotion</a></h2>
								<p>120 listings</p>
							</div>
						</li>
					</ul>
				</div>
			</div>

		</section> */}
      {/* <Footer /> */}
      {/* <AboutCard /> */}
      {/* <HAbout />
      <Testimonal />
      <Hblog /> */}
      {/* <Hprice /> */}
    </>
  )
}

export default Home
