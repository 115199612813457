import Axios from 'axios';
/**
 *
 * @param  url, method, data, ....
 * @Description fetch the data from the api based on the type of request
 * @returns api response
 */
const apiCall = async (URL, METHOD, DATA = '', token = '') => {
  console.log(token, 'token');
  return new Promise((resolve) => {
    let hasError = false;
    Axios({
      method: METHOD,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
       
      },
      maxBodyLength: Infinity,
      url: URL,
      data: JSON.stringify(DATA)
    })
      .then((response) => {
        resolve({
          status: response.status,
          response,
          hasError
        });
      })
      .catch((err) => {
        hasError = true;
        resolve({
          status: err,
          response: err,
          hasError
        });
      });
  });
};

export default apiCall;