



// src/components/SignUp.js
import React, { useState } from "react"

import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
 
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";
import apiCall  from "../../api/Requesthandler";
import { REQUEST_TYPE, SIGN_UP } from "../../api/apiConfig";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Head from "../common/header/Head";
import "./signuphome.css"


const Signupcard = () => {
    const navigation = useNavigate();
	const [formData, setFormData] = useState({
        firstName: '',
        middleName:'',
        lastName:'',
        email: '',
        number:'',
        password: '',
        repeatPassword: '',
    });
    const [passwordError, setPasswordError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
        if (formData.password !== formData.repeatPassword) {
			console.log('p d m');
            //toast('Passwords do not match');
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Passwords do not match!",
                showConfirmButton: false,
                timer: 2000
              });
            
            return false;
        }
        
    

            apiCall(SIGN_UP.signup,REQUEST_TYPE.POST,formData).then((res)=>{

                console.log(res, '-----------');

                	if(res.status ==200){
				//toast(res.response.data.message)
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: res.response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                  });
                Cookies.set('userId',res.response.data.user._id)

                const data = { key: res.response.data.user.email};

                // Encode data as JSON and encode it to base64 to be sent in the URL
                const encodedData = btoa(JSON.stringify(data));
				setTimeout(() => {
					navigation(`/activateUser/${encodedData}`)
				}, 1000);


            }
            else {
                console.log(res,'hi');
                console.log(res.response.response);
                //toast(res.response.response.data.message)
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: res.response.response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                  });
            }

            })
            
            // Assuming the response contains data you want to handle
            // Handle success, such as redirecting to a new page or displaying a success message
     
    };
  return (
    <>
    <Head />
    <div className="signup-container">
      <h2 className="signup-title">Sign up as a student</h2>
      <p>
        Already have an account? <a href="/login">Log in</a>
      </p>

      {/* <div className="social-login">
        <button className="social-button google">Continue with Google</button>
        <button className="social-button facebook">Continue with Facebook</button>
      </div>

      <p className="or-divider">or</p> */}

      <form className="signup-form" onSubmit={handleSubmit}>
        <input type="text" placeholder="Your first name" className="signup-input" name="firstName"  value={formData.firstName} onChange={handleChange} required/>
        <input type="text" placeholder="Your last name" className="signup-input"  name="lastName"  value={formData.lastName} onChange={handleChange} required />
        <input type="email" placeholder="Your email" className="signup-input" name="email" value={formData.email} onChange={handleChange}  required />
        <input type="number" placeholder="Your phone" id="number" name="number" className="signup-input"  value={formData.number} onChange={handleChange} required/>
        <input
          type="password"
          placeholder="Your password"
          className="signup-input"
          name="password"
          value={formData.password} onChange={handleChange}  required
        />
          <input
          type="password"
          placeholder="Repeat your password"
          className="signup-input"
          name="repeatPassword" value={formData.repeatPassword} onChange={handleChange}  required
        />
        <div className="remember-me">
          <input type="checkbox" id="remember" />
          <label htmlFor="remember">Remember me</label>
        </div>
        <button type="submit" className="signup-button">Sign up</button>
      </form>

      <p className="terms">
        By clicking Continue or Sign up, you agree to{" "}
        <a href="/terms">Preply Terms of Use</a>, including{" "}
        <a href="/subscription">Subscription Terms</a> and{" "}
        <a href="/privacy">Privacy Policy</a>.
      </p>
    </div>
    <ToastContainer/>
    </>
  );
};

export default Signupcard;

