import { Outlet, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import apiCall from '../../api/Requesthandler';
import { GET_USER, REQUEST_TYPE } from '../../api/apiConfig';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/loader';

const PrivateRoutes = () => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userId = Cookies.get('userId');

  useEffect(() => {
    if (userId !== undefined) {
      // Make the API call in useEffect
      apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
        console.log(res.hasError, 'response');
        setHasError(res.hasError);
        setIsLoading(false); // Stop loading once the response is received
      }).catch(() => {
        setHasError(true);
        setIsLoading(false); // Stop loading in case of error
      });
    } else {
      // Redirect to login if userId is not found
      navigate('/login');
    }
  }, [userId, navigate]); // Dependencies to avoid infinite re-render

  // Show a loading indicator while the API call is in progress
  if (isLoading) return <Loader/>;

  return !hasError ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
