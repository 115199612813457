import React, { useEffect, useRef } from "react"
import Back from "../common/back/Back"
import BlogCard from "./BlogCard"
import "./blog.css"

const Blog = () => {

  const componentRef = useRef(null);
  useEffect(() => {
    if (componentRef.current) {
componentRef.current.scrollIntoView({ behavior: 'smooth' });
}
}, []);
  return (
    <>
      <Back title='Sessions Posts' />
      <section className='blog padding'>
        <div className='container grid2' ref={componentRef}>
          <BlogCard />
        </div>
      </section>
    </>
  )
}

export default Blog
