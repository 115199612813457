export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "FluencifyAI is",
    desc: "FluencifyAI is a leading-edge company dedicated to revolutionizing the way people learn and improve their spoken English skills. With a team comprised of highly experienced professionals boasting years of expertise in teaching spoken English, FluencifyAI is committed to providing innovative solutions that empower individuals to communicate confidently and effectively in English.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "At FluencifyAI",
    desc: "At FluencifyAI, we're on a mission to revolutionize the way people learn and master new languages. Our innovative platform harnesses the power of artificial intelligence to make language learning not just effective, but also engaging and tailored to your unique needs.",
  }

  // {
  //   id: 1,
  //   cover: "https://img.icons8.com/ios/80/000000/diploma.png",
  //   title: "At FluencifyAI",
  //   desc: "At FluencifyAI, we understand the importance of spoken English proficiency in today's globalized world. Whether for academic, professional, or personal purposes, the ability to articulate thoughts, ideas, and opinions fluently in English can open doors to a myriad of opportunities. That's why we've made it our mission to leverage cutting-edge technology and pedagogical expertise to offer unparalleled resources and support for language learners worldwide.",
  // }
]
export const homeAbout2 = [
  // {
  //   id: 1,
  //   cover: "https://img.icons8.com/ios/80/000000/athlete.png",
  //   title: "Key Pillars of FluencifyAI",
  //   desc: "One of the key pillars of FluencifyAI's approach is our team of seasoned professionals. Each member of our team brings a wealth of experience and knowledge to the table, with years of hands-on experience in teaching spoken English to students of diverse backgrounds and proficiency levels. From language educators and linguists to software developers and AI specialists, our team collaborates seamlessly to develop and deliver innovative solutions that address the unique needs and challenges faced by language learners.",
  // },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Who We Are",
    desc: "FluencifyAI was founded by a team of passionate linguists, educators, and tech enthusiasts who believe that language is the key to unlocking new cultures, opportunities, and perspectives. Our diverse team brings together expertise in AI technology, language education, and user experience design, all dedicated to creating a language learning tool that is as dynamic and adaptable as you are.",
  },
  // {
  //   id: 1,
  //   cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
  //   title: "Our Commitments",
  //   desc: "Our commitment to excellence extends beyond our team to the very core of our products and services. At FluencifyAI, we harness the power of artificial intelligence (AI) and machine learning to create immersive, interactive, and personalized learning experiences that adapt to each learner's individual needs and preferences. Through our state-of-the-art AI-driven platform, learners have access to a vast array of speaking practice exercises, real-time feedback, pronunciation drills, vocabulary building activities, and more, all designed to enhance fluency and confidence in spoken English.",
  // }
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Our Vision",
    desc: "Our vision is to make language learning accessible, enjoyable, and personalized to all. We understand that every learner is different, and our AI-driven platform is designed to adapt to your individual learning style and pace. Whether you're a beginner or looking to perfect your skills, FluencifyAI offers a tailored experience to help you achieve fluency faster and more effectively.",
  }
]

export const homeAbout3 = [
  // {
  //   id: 1,
  //   cover: "https://img.icons8.com/ios/80/000000/athlete.png",
  //   title: "Innovation and Continuous Improvement",
  //   desc: "What sets FluencifyAI apart is our unwavering dedication to innovation and continuous improvement. We are constantly refining and expanding our platform to incorporate the latest advancements in AI technology and language pedagogy, ensuring that our users always have access to the most effective and up-to-date learning tools available. Whether through incorporating new features, expanding content offerings, or refining our algorithms, we are committed to staying at the forefront of language learning innovation.",
  // },
  // {
  //   id: 1,
  //   cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
  //   title: "Community and Collaboration",
  //   desc: "Moreover, at FluencifyAI, we believe in the power of community and collaboration. Our platform fosters a vibrant and supportive learning community where users can connect with fellow learners, share experiences, and exchange insights. Through live sessions, discussion forums, and peer-to-peer interaction, learners can enhance their language skills in a collaborative and engaging environment.",
  // }
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Our Mission",
    desc: "At FluencifyAI, our mission is to empower individuals to master new languages with confidence and ease through innovative, AI-driven solutions. We are dedicated to transforming language learning into a personalized, engaging, and effective journey, making it accessible to everyone, everywhere. Our goal is to bridge cultural divides and open up new opportunities by providing the tools and support needed to achieve true fluency.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "What We Offer",
    desc: "Personalized Learning Paths: Our AI technology assesses your current level and learning goals to create a customized learning plan just for you.\nInteractive Practice: Engage in real-world conversations with our intelligent language models and receive instant feedback to improve your skills.\n\ Adaptive Lessons: Our platform evolves with your progress, offering lessons and exercises that challenge and enhance your abilities.\n\Cultural Insights: Gain a deeper understanding of the language by exploring cultural contexts and nuances through our engaging content.",
  }
]

export const homeAbout4= [
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Why Choose FluencifyAI?",
    desc: "Cutting-Edge Technology: We leverage the latest advancements in AI to provide a learning experience that's both innovative and effective.\n Expertise and Experience: Our team's combined experience in language education and technology ensures that you receive the best possible support. \nUser-Centric Design: We prioritize your learning experience by continually refining our platform based on user feedback and evolving educational practices.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Join Us on Your Learning Journey",
    desc: "Whether you're learning a new language for travel, work, or personal growth, FluencifyAI is here to support you every step of the way. Our goal is to make language learning a seamless and rewarding experience that opens doors to new possibilities.Thank you for choosing FluencifyAI. We look forward to helping you achieve your language goals and celebrate your milestones along the way!",
  }

]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "English Spoken",
    link: "/CourseHome/English_Spoken",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Avatar",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$1000 All Course",

  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "Grammar",
    link: "/CourseHome/Grammar",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Avatar",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$1000 All Course",

  },
  // {
  //   id: 3,
  //   cover: "../images/courses/c3.png",
  //   coursesName: "HTML, CSS, and Javascript for Web Developers",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Saroj Nepal",
  //       totalTime: "50 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$50 All Course",
  //   pricePer: "$5 per month",
  // },
  // {
  //   id: 4,
  //   cover: "../images/courses/c4.png",
  //   coursesName: "Introducing to Programming with WordPress",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Prabin Thapa Mager",
  //       totalTime: "20 lectures (20 hrs)",
  //     },
  //   ],
  //   priceAll: "$30 All Course",
  //   pricePer: "$3 per month",
  // },
  // {
  //   id: 5,
  //   cover: "../images/courses/c5.png",
  //   coursesName: "Introducing to Programming with ReactJS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "100 lectures (150 hrs)",
  //     },
  //   ],
  //   priceAll: "$300 All Course",
  //   pricePer: "$30 per month",
  // },
  // {
  //   id: 6,
  //   cover: "../images/courses/c6.png",
  //   coursesName: "Learn Frontend Programming Language",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "200 lectures (300 hrs)",
  //     },
  //   ],
  //   priceAll: "$500 All Course",
  //   pricePer: "$80 per month",
  // },
  // {
  //   id: 7,
  //   cover: "../images/courses/c7.png",
  //   coursesName: "Introducing to with HTML / CSS",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Price",
  //       totalTime: "20 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$2 per month",
  // },
  // {
  //   id: 8,
  //   cover: "../images/courses/c8.png",
  //   coursesName: "Introducing to with JAVA",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "80 lectures (200 hrs)",
  //     },
  //   ],
  //   priceAll: "$200 All Course",
  //   pricePer: "$40 per month",
  // },
  // {
  //   id: 9,
  //   cover: "../images/courses/c9.png",
  //   coursesName: "Introducing to with HTML / CSS / JAVASCRIPT",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Petter",
  //       totalTime: "50 lectures (50 hrs)",
  //     },
  //   ],
  //   priceAll: "$20 All Course",
  //   pricePer: "$3 per month",
  // },
]
export const online = [
  {
    cover: "../images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "Is this",
    // course: "25 Courses",
  },
  {
    cover: "../images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Was this",
    // course: "25 Courses",
  },
  {
    cover: "../images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Are these those",
    // course: "30 Courses",
  },
  {
    cover: "../images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "Were these",
    // course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Is adjective",
    // course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: " Was adjective",
    // course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: " Are adjective",
    // course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Were adjedctive.",
    // course: "30 Courses",
  },
  // {
  //   cover: "./images/courses/online/o9.png",
  //   hoverCover: "./images/courses/online/o9.1.png",
  //   courseName: "Graphic Design",
  //   course: "80 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o10.png",
  //   hoverCover: "./images/courses/online/o10.1.png",
  //   courseName: "Music",
  //   course: "120 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Business Administration",
  //   course: "17 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
]
export const online2 = [
  {
    cover: "../images/courses/online/o1.png",
    hoverCover: "../images/courses/online/o1.1.png",
    courseName: "Beginner",
    // course: "25 Courses",
  },
  {
    cover: "../images/courses/online/o2.png",
    hoverCover: "../images/courses/online/o2.1.png",
    courseName: "Intermediate",
    // course: "25 Courses",
  },
  {
    cover: "../images/courses/online/o5.png",
    hoverCover: "../images/courses/online/o5.1.png",
    courseName: "Advance",
    // course: "30 Courses",
  },
  // {
  //   cover: "../images/courses/online/o4.png",
  //   hoverCover: "./images/courses/online/o4.1.png",
  //   courseName: "Were these",
  //   // course: "15 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o3.png",
  //   hoverCover: "./images/courses/online/o3.1.png",
  //   courseName: "Is adjective",
  //   // course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o6.png",
  //   hoverCover: "./images/courses/online/o6.1.png",
  //   courseName: " Was adjective",
  //   // course: "60 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o7.png",
  //   hoverCover: "./images/courses/online/o7.1.png",
  //   courseName: " Are adjective",
  //   // course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o8.png",
  //   hoverCover: "./images/courses/online/o8.1.png",
  //   courseName: "Were adjedctive.",
  //   // course: "30 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o9.png",
  //   hoverCover: "./images/courses/online/o9.1.png",
  //   courseName: "Graphic Design",
  //   course: "80 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o10.png",
  //   hoverCover: "./images/courses/online/o10.1.png",
  //   courseName: "Music",
  //   course: "120 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Business Administration",
  //   course: "17 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
]

export const online3 = [
  {
    // cover: "../images/courses/online/o1.png",
    // hoverCover: "../images/courses/online/o1.1.png",
    courseName: "Level 1",

    // course: "25 Courses",
  },
  {

    courseName: "Level 2",
    // course: "25 Courses",
  },
  {

    courseName: "Level 3",
    // course: "30 Courses",
  },
  {

    courseName: "Level 4",
    // course: "30 Courses",
  },
  {

    courseName: "Level 5",
    // course: "30 Courses",
  },
  // {
  //   cover: "../images/courses/online/o4.png",
  //   hoverCover: "./images/courses/online/o4.1.png",
  //   courseName: "Were these",
  //   // course: "15 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o3.png",
  //   hoverCover: "./images/courses/online/o3.1.png",
  //   courseName: "Is adjective",
  //   // course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o6.png",
  //   hoverCover: "./images/courses/online/o6.1.png",
  //   courseName: " Was adjective",
  //   // course: "60 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o7.png",
  //   hoverCover: "./images/courses/online/o7.1.png",
  //   courseName: " Are adjective",
  //   // course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o8.png",
  //   hoverCover: "./images/courses/online/o8.1.png",
  //   courseName: "Were adjedctive.",
  //   // course: "30 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o9.png",
  //   hoverCover: "./images/courses/online/o9.1.png",
  //   courseName: "Graphic Design",
  //   course: "80 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o10.png",
  //   hoverCover: "./images/courses/online/o10.1.png",
  //   courseName: "Music",
  //   course: "120 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Business Administration",
  //   course: "17 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
]

export const online4 = [
  {
    // cover: "../images/courses/online/o1.png",
    // hoverCover: "../images/courses/online/o1.1.png",
    courseName: "Day 1",
    data: [
      {
        id:1,
        title: "Is that a bag",
        link: "videos/Is that a bag _.mov",
        ans: "yes that's a bag"
      },
      {
        id:2,
        title: "Is that a book",
        link: "videos/Is that a book _.mov",
        ans: "yes that's a book"
      },
      {
        id:3,
        title: "Is that a cup",
        link: "videos/Is that a cup_.mov",
        ans: "yes that's a cup"
      },
      {
        id:4,
        title: "Is that a spoon",
        link: "videos/Is that a spoon _.mov",
        ans: "yes that's a spoon",
      },{
        id:5,
        title: "Is this a cat",
        link: "videos/Is this a cat _.mov",
        ans: "yes that's a cat",
      }, {
        id:6,
        title: "Is this a dish",
        link: "videos/Is this a dish_.mov",
        ans: "yes this is a dish",
      }, {
        id:7,
        title: "Is this a plate",
        link: "videos/Is this a plate _.mov",
        ans: "yes this is a plate",
      }, 
      // {
      //   id:8,
      //   title: "Is this a shoes",
      //   link: "videos/Is this a shoes _.mov",
      //   ans: "yes this is a shoes",
      // }, 


    ]
    // course: "25 Courses",
  },
  {

    courseName: "Day 2",
    data: [
      {
        id:6,
        title: "Is this a dish",
        link: "videos/Is this a dish_.mov",
        ans: "yes this is a dish",
      },{
      id:7,
      title: "Is this a plate",
      link: "videos/Is this a plate _.mov",
      ans: "yes this is a plate",
    }, 
    ]
    // course: "25 Courses",
  },
  {

    courseName: "Day 3",
    data:[{
      id:111,
      title: "Is that a book",
      link: "videos/Is that a book _.mov",
      ans: "yes that's a book",
    },
    {
      id:7,
      title: "Is this a plate",
      link: "videos/Is this a plate _.mov",
      ans: "yes this is a plate",
    }, ]
    // course: "30 Courses",
  },
  {

    courseName: "Day 4",
    // course: "30 Courses",
  },
  {

    courseName: "Day 5",
    // course: "30 Courses",
  },
  {

    courseName: "Day 6",
    // course: "30 Courses",
  },
  {

    courseName: "Day 7",
    // course: "30 Courses",
  },
  {

    courseName: "Day 8",
    // course: "30 Courses",
  },
  {

    courseName: "Day 9",
    // course: "30 Courses",
  },
  {

    courseName: "Day 10",
    // course: "30 Courses",
  },
  // {
  //   cover: "../images/courses/online/o4.png",
  //   hoverCover: "./images/courses/online/o4.1.png",
  //   courseName: "Were these",
  //   // course: "15 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o3.png",
  //   hoverCover: "./images/courses/online/o3.1.png",
  //   courseName: "Is adjective",
  //   // course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o6.png",
  //   hoverCover: "./images/courses/online/o6.1.png",
  //   courseName: " Was adjective",
  //   // course: "60 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o7.png",
  //   hoverCover: "./images/courses/online/o7.1.png",
  //   courseName: " Are adjective",
  //   // course: "10 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o8.png",
  //   hoverCover: "./images/courses/online/o8.1.png",
  //   courseName: "Were adjedctive.",
  //   // course: "30 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o9.png",
  //   hoverCover: "./images/courses/online/o9.1.png",
  //   courseName: "Graphic Design",
  //   course: "80 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o10.png",
  //   hoverCover: "./images/courses/online/o10.1.png",
  //   courseName: "Music",
  //   course: "120 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o11.png",
  //   hoverCover: "./images/courses/online/o11.1.png",
  //   courseName: "Business Administration",
  //   course: "17 Courses",
  // },
  // {
  //   cover: "./images/courses/online/o12.png",
  //   hoverCover: "./images/courses/online/o12.1.png",
  //   courseName: "Web Management",
  //   course: "17 Courses",
  // },
]
export const team = [
  {
    cover: "./images/lady.jpg",
    name: "Ph.D Sukhpreet Kaur Sandhu",
    work: "MBA",
  },
  {
    cover: "./images/gentalman.jpg",
    name: "Ph.D Dilbagh Singh Sandhu",
    work: "B.A. AND B.Ed.",
  },
  // {
  //   cover: "./images/team/t3.webp",
  //   name: "Ph.D Anna Hanzen",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t4.webp",w
  //   name: "Ph.D Brian Wooden",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t5.webp",
  //   name: "Ph.D Adrian Molises",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t6.webp",
  //   name: "Ph.D Arthur MaGregor",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t7.webp",
  //   name: "Ph.D Anna Hanzen",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t8.webp",
  //   name: "Ph.D Brian Wooden",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "What is the purpose of this website?",
    desc: "The purpose of this website is to provide a comprehensive platform for individuals to improve their spoken English skills. Our goal is to offer engaging and interactive speaking practice exercises, coupled with learning resources, to help users enhance their fluency, pronunciation, vocabulary, and confidence in speaking English. Whether you're a beginner looking to build a foundation or an advanced learner striving for fluency, our website aims to cater to your needs and support you on your language learning journey. Moreover, the purpose of this website is to provide a platform for students to refer more students and earn rewards in the process. It serves as a hub where students can access resources and tools to enhance their learning experience while also providing them with an opportunity to monetize their network by referring others to the platform.",
  },
  {
    title: "How can I earn from this website?",
    desc: "To earn from our website through a referral program where students enrol other students and receive a weekly payout of 20%, you can follow these steps: Implement a Referral System: Develop a referral system where each student is assigned a unique referral code or link. Track Referrals: Set up a tracking mechanism to monitor referrals made by students using their unique codes or links. This system should accurately record when a new student signs up using a referral code and attribute the referral to the referring student. Calculate Payouts: Determine the payout structure. In this case, students will receive a weekly payout equivalent to 20% of the revenue generated from the students they referred who have paid for the service or product. Distribute Payouts: Set up a process to distribute payouts to students on a weekly basis. Ensure that payments are made promptly and accurately to incentivize continued participation in the referral program. Communicate the Program: Clearly communicate the referral program to all students. Provide details on how they can obtain their unique referral codes, how referrals are tracked, and how payouts are calculated and distributed. Monitor and Adjust: Regularly monitor the performance of the referral program. Analyze metrics such as the number of referrals, conversion rates, and revenue generated. Make adjustments to the program as needed to optimize performance and ensure fairness. Promote the Program: Encourage students to participate in the referral program by promoting its benefits. Highlight the opportunity to earn passive income through referrals and emphasize the simplicity of the process. By implementing and effectively managing a referral program, you can incentivize students to refer others to your website, thereby increasing your user base and revenue while rewarding students for their efforts in growing the platform.",
  },
  {
    title: "What specific professions or occupations can benefit the most from using the website to enhance their spoken English skills?",
    desc: "People from various professions and backgrounds can benefit from using the website. Here are some examples of professions that could benefit:1. Students who are aspiring to take English Proficiency Test to study abroad. 2.	Students: Students who want to improve their spoken English skills for academic purposes or future career opportunities.3.	Professionals: Professionals in fields such as business, healthcare, engineering, IT, and others who require strong communication skills in English for their work. 4.	Teachers and Educators: Teachers and educators who want to enhance their English language teaching skills or provide additional resources for their students.5.	Job Seekers: Job seekers who need to improve their English proficiency for job interviews, resume writing, and workplace communication. 6.	Expatriates: Expatriates or individuals living in English- speaking countries who want to improve their language skills for better integration into the local community. 7.	Entrepreneurs: Entrepreneurs and business owners who need to communicate effectively with clients, partners, and stakeholders in English. 8.	Travelers: Travelers who want to improve their English language skills for better communication while traveling or living abroad. Overall, people from various professions and walks of life can benefit from using the website to improve their spoken English skills and enhance their communication abilities.",
  },
  {
    title: "How can I improve my spoken English skills using this website?",
    desc: "Our website offers a unique and effortless approach to learning spoken English by leveraging the mother tongue technique. With our innovative methodology, students simply need to close their eyes and answer unlimited questions covering grammar, sentence practice, vocabulary, and pronunciation—all in spoken form. There's no need to memorize grammar rules or spend time on traditional learning methods. Instead, students can focus on speaking naturally and fluently. Features like performance assessment and feedback allow users to track their progress and receive valuable guidance for improvement. Additionally, captions and translations are provided to aid comprehension, and users have the option to listen to their own voices, allowing for self-assessment and correction. Our platform empowers learners to effortlessly enhance their spoken English skills through immersive, intuitive, and personalized learning experiences.",
  },
  {
    title: "How mother tongue technique works?",
    desc: "The innovation of the mother tongue technique has the potential to outdate traditional language learning methods. Unlike traditional techniques that often involve memorization of grammar rules and vocabulary lists, the mother tongue technique taps into the natural language acquisition process by allowing learners to use their native language as a bridge to learn a new language. By leveraging the familiarity and comfort of the learner's mother tongue, the mother tongue technique makes the learning process more intuitive, engaging, and effective. Learners can focus on speaking and practicing without the need to consciously translate or memorize grammar rules. This approach promotes fluency and natural communication skills, which are essential for real-world language use. Additionally, the mother tongue technique is highly adaptable and inclusive, catering to learners of all ages, backgrounds, and proficiency levels. It eliminates the barriers and frustrations often associated with traditional language learning methods, making language acquisition more accessible and enjoyable for everyone. As more learners and educators recognize the effectiveness of the mother tongue technique, it is likely to become increasingly popular and widely adopted, eventually overshadowing traditional techniques. Its ability to streamline the learning process and produce confident, proficient speakers makes it a promising innovation in the field of language education.",
  },
  {
    title: "Is this website suitable for beginners or advanced learners?",
    desc: "Yes, this website is suitable for both beginners, intermediate and advanced learners alike. The approach of utilizing the mother tongue technique makes it accessible to learners at all proficiency levels. For beginners, the website offers a gentle introduction to spoken English, allowing them to practice and improve their skills without feeling overwhelmed. They can start by answering very simple questions and gradually progress to more complex ones as they gain confidence. Advanced learners can benefit from the website's extensive range of speaking questionnaire, which cover a wide variety of topics and language nuances. They can use the platform to refine their pronunciation, expand their vocabulary, and fine-tune their speaking skills to a higher level of proficiency. Overall, whether you're just starting out on your language learning journey or seeking to further enhance your spoken English abilities, this website provides valuable resources and support tailored to your individual needs.",
  },
  {
    title: "What kind of speaking practice does this website offer?",
    desc: "",
  },
  {
    title: "Are there specific topics or themes covered in the speaking exercises?",
    desc: "Yes, the speaking exercises on this website cover a wide range of specific topics and themes to cater to the diverse interests and needs of learners. Some common topics and themes include: a). Everyday Conversations: Practice speaking about daily activities, routines, and personal experiences. b). Travel and Culture: Discussing travel destinations, cultural customs, and experiences abroad. c). Work and Career: Talking about job roles, workplace dynamics, and career aspirations. d). Education: Discussing academic subjects, learning experiences, and educational goals. e). Hobbies and Interests: Sharing interests, hobbies, and leisure activities. f). Health and Wellness: Talking about exercise routines, healthy habits, and wellness practices. g). Technology: Discussing gadgets, apps, and technological advancements. h). Current Events: Engaging in discussions about news, politics, and current affairs. I). Entertainment: Talking about movies, music, books, and other forms of entertainment. j).Food and Cuisine: Discussing recipes, cooking techniques, and culinary preferences.  These topics and themes are designed to provide learners with relevant and engaging content that encourages them to practice speaking naturally and fluently. Additionally, learners can also suggest specific topics or themes they would like to see covered in the speaking exercises, allowing for customization and personalization based on individual interests and preferences.",
  },
  {
    title: "Do I need any special equipment or software to use this website?",
    desc: "No, you do not need any special equipment or software to use this website. Our platform is designed to be accessible and user-friendly, requiring only basic equipment and internet connectivity. To access the speaking exercises and learning resources, all you need is a device with a web browser, such as a computer, laptop, tablet, or smartphone. As long as you have access to the internet, you can visit our website and start practicing spoken English immediately. There is no need to download or install any additional software. Our website is optimized for use on various devices and operating systems, ensuring a seamless and hassle-free learning experience for all users. Whether you're at home, in the office, or on the go, you can easily access our website and engage in speaking practice exercises anytime, anywhere, without the need for any special equipment or software.",
  },
  {
    title: "Are there any live sessions or interactive features available?",
    desc: "Yes, our website offers live sessions and interactive features to enhance your learning experience and provide additional opportunities for practice and engagement. Here are some concepts to understand about these features: Live Sessions: We may conduct live sessions where learners can participate in real-time spoken English practice with instructors or language coaches. In which we will teach how to use this website efficiently. These sessions also include activities such as group discussions, role-playing exercises, and Q&A sessions Interactive Exercises: Our platform provides various interactive exercises designed to engage learners in active participation. These exercises may include quizzes, games, speaking prompts, and simulations that encourage learners to apply their spoken English skills in practical contexts. Chat Rooms or Forums: We offer chat rooms or forums where learners can interact with each other, ask questions, and discuss topics related to spoken English. These platforms foster a sense of community and provide additional opportunities for peer learning and support. Virtual Classroom: Some features may simulate a virtual classroom environment, where learners can join scheduled sessions, interact with instructors and peers, and engage in collaborative activities aimed at improving spoken English proficiency. Feedback and Correction: Interactive features may include feedback and correction mechanisms that provide learners with immediate guidance on their spoken English performance. This real-time feedback helps learners identify areas for improvement and adjust their speaking habits accordingly. Overall, these live sessions and interactive features complement the core learning resources on our website, providing learners with dynamic and engaging opportunities to practice and enhance their spoken English skills in an interactive and supportive environment.",
  },
  {
    title: "How often should I practice on this website to see improvement?",
    desc: "To see noticeable improvement in your spoken English skills, it is recommended to practice regularly on our website. While the frequency of practice may vary depending on your schedule and learning goals, practicing at least 50 to 100 questions daily can be highly beneficial. Consistent practice is key to reinforcing language acquisition and developing fluency. By practicing daily, you allow your brain to become more accustomed to speaking English, making it a natural and effortless habit over time. Additionally, practicing a substantial number of questions daily provides your tongue with sufficient exercise, helping improve pronunciation, fluency, and overall speaking confidence. However, it's essential to balance quantity with quality. Focus on engaging deeply with each question, paying attention to pronunciation, grammar, and vocabulary usage. Reflect on feedback provided and make adjustments as needed to ensure continuous progress. Ultimately, the more frequently and consistently you practice on our website, the faster you are likely to see improvement in your spoken English skills. Make practicing English a part of your daily routine, and you'll gradually notice significant strides in your language proficiency.",
  },
  {
    title: "Are the speaking exercises tailored to different proficiency levels?",
    desc: "Yes, the speaking exercises on our website are tailored to different proficiency levels to accommodate learners with varying levels of English proficiency. We offer a structured approach with multiple levels of difficulty to ensure that learners can progress at their own pace and challenge themselves appropriately. Our speaking exercises are categorized into three main proficiency levels: Basic, Intermediate, and Advanced. Within each proficiency level, there are further subdivisions to provide a more nuanced learning experience. For example, in the Basic and Intermediate levels, there are five sub-levels to cater to learners' needs at different stages of proficiency. Each level and sub-level is carefully designed to target specific language skills and areas of improvement, such as vocabulary acquisition, grammar comprehension, pronunciation practice, and fluency development. The exercises gradually increase in complexity and difficulty as learners advance through the levels, allowing them to build upon their existing knowledge and skills systematically. By offering a comprehensive range of speaking exercises tailored to different proficiency levels, we ensure that learners can find appropriate challenges and opportunities for growth regardless of their starting point. This approach enables learners to progress steadily and confidently towards achieving their language learning goals.",
  },
  {
    title: "Is there a community or forum where I can interact with other learners?",
    desc: "Yes, our website features a community forum where learners can interact freely with each other and engage in discussions related to spoken English practice, language learning tips, cultural topics, and more. The forum serves as a valuable platform for learners to connect with peers, share experiences, ask questions, and provide support to one another throughout their language learning journey. Within the forum, you can participate in various threads and topics, ask for advice or feedback on specific language-related challenges, share resources and study materials, and engage in conversations with fellow learners from around the world. Whether you're seeking motivation, clarification on language concepts, or simply looking to connect with like-minded individuals, the community forum provides a welcoming and supportive environment for learners of all levels. Additionally, the forum may be moderated by experienced language instructors or moderators who can provide guidance, answer questions, and facilitate productive discussions. This ensures that interactions remain respectful, constructive, and conducive to learning. Overall, the community forum serves as a valuable supplement to the speaking exercises and learning resources on our website, allowing learners to collaborate, learn from each other, and foster a sense of camaraderie as they work towards improving their spoken English skills.",
  },
  {
    title: "Can I receive feedback on my speaking exercises?",
    desc: "Yes, you can receive feedback on your speaking exercises after each question. Our platform is equipped with a feedback mechanism that provides immediate guidance and evaluation of your spoken English performance. After completing a speaking exercise, you will receive feedback on various aspects of your response, including pronunciation, grammar, vocabulary usage, fluency, and overall clarity. This feedback is tailored to help you identify areas for improvement and refine your speaking skills. The feedback may include specific suggestions for pronunciation corrections, grammatical errors, vocabulary choices, and sentence structure enhancements. Additionally, you may receive praise for areas where you excel, helping to reinforce positive speaking habits and build confidence. By receiving feedback after each question, you can track your progress, learn from your mistakes, and continuously refine your spoken English skills. This personalized feedback mechanism is designed to support your language learning journey and help you achieve your goals effectively.",
  },
  {
    title: "Are there any additional resources or materials available for learning spoken English?",
    desc: "Yes, in addition to the speaking exercises, our website offers a wealth of additional resources and materials to support your learning of spoken English. One of the key resources available is a vast library of over 25,000 questions designed to provide you with a diverse range of vocabulary and language structures. These questions cover various topics, themes, and scenarios, allowing you to explore different aspects of spoken English and expand your vocabulary in context. By engaging with a wide range of questions, you can enhance your language proficiency and become more comfortable expressing yourself in different situations. Furthermore, our platform may offer supplementary materials such as: Vocabulary Lists: Curated lists of common English words and phrases organized by topic or theme to help you expand your vocabulary systematically. Grammar Guides: Clear and concise explanations of English grammar rules and concepts to support your understanding and usage of correct grammar in spoken English. Pronunciation Resources: Audio recordings, phonetic guides, and pronunciation exercises to help you improve your pronunciation and intonation patterns. Conversation Practice Guides: Tips, strategies, and sample dialogues to help you practice conversational skills and engage in meaningful interactions with others. Language Learning Tips: Articles, blog posts, and tutorials offering valuable insights, strategies, and techniques for effective language learning and spoken English improvement. These additional resources complement the speaking exercises and provide you with comprehensive support and guidance throughout your language learning journey. Whether you're a beginner looking to build a foundation or an advanced learner striving for fluency, our platform offers the tools and materials you need to succeed in mastering spoken English.",
  },
  {
    title: "Is there a mobile app available for practicing on-the-go?",
    desc: "As of now, we do not have a mobile app available for practicing spoken English on-the-go. However, our website is optimized for mobile devices, allowing you to access and engage with the speaking exercises and learning materials from your smartphone or tablet's web browser. While we understand the convenience of having a dedicated mobile app, rest assured that our website offers a seamless and user-friendly experience on mobile devices. You can practice spoken English anytime, anywhere, whether you're commuting, traveling, or simply taking a break throughout your day.We continuously strive to improve and expand our offerings, and the development of a mobile app may be considered in the future to further enhance your language learning experience. In the meantime, we appreciate your understanding and encourage you to make the most of our website's mobile-friendly features to practice spoken English at your convenience.",
  },
  {
    title: "How do I sign up and get started with using the website?",
    desc: "To sign up and get started with using our website, follow these simple steps: Visit our website's homepage using your preferred web browser. Look for the 'Sign Up' or 'Register' button/link. This is usually located at the top right corner of the webpage or in a prominent position on the homepage. Click on the 'Sign Up' or 'Register' button/link to proceed to the registration page. On the registration page, you'll be asked to provide some basic information such as your name, email address, and a password. Fill in the required fields with accurate information. Once you've filled in the necessary information, click on the 'Sign Up' or 'Register' button to create your account. After successfully registering, you may receive a confirmation email. Follow the instructions in the email to verify your account and activate your membership. Once your account is activated, you can log in using your email address and password. Upon logging in, you'll have access to the website's features, including speaking exercises, learning resources, community forums, and more. Start exploring the website, choose your desired proficiency level, and begin practicing spoken English at your own pace. That's it! You're now signed up and ready to start improving your spoken English skills with our website. If you encounter any issues during the registration process or need assistance, feel free to reach out to our customer support team for help.",
  },
  {
    title: "Are there any fees or subscriptions required to access the speaking practice exercises?",
    desc: "Yes, there are two versions available for accessing the speaking practice exercises on our website. The standard version is priced at 1000 + taxes per month, while the pro version, which likely offers additional features or benefits, is priced at 2000 + taxes per month.",
  },
  {
    title: "Can I access the website from anywhere in the world?",
    desc: "Yes, you can access the website from anywhere in the world as long as you have an internet connection and a compatible device such as a computer, smartphone, or tablet. Our website is accessible globally, allowing users from different countries to participate in the speaking practice exercises and other features offered on the platform.",
  },
  {
    title: "Are there any age restrictions for using the website? ",
    desc: "No, there are no age restrictions for using the website. Users of all ages are welcome to access and participate in the speaking practice exercises and other features offered on the platform.",
  },
  {
    title: "How do I contact customer support if I have any questions or issues?",
    desc: "",
  },
]
export const blog = [
  {
    id: 1,
    type: "Ph.D Sukhpreet Kaur",
    date: "JAN. 18, 2021",
    com: "MBA",
    title: "Speak English Sessions",
    desc: "Each session lasts for 26 days, excluding Sundays. The timing of each session can be either half an hour or one hour, based on the user's preference.",
    //cover: "../images/blog/b1.webp",
    cover: "../images/blog/english-speeking.jpg"
  },
  {
    id: 2,
    type: "Ph.D Dilansh Singh",
    date: "API. 25, 2022",
    com: "B.A. AND B.Ed.",
    title: "IELTS Sessions",
    desc: "Each session lasts for 26 days, excluding Sundays. The timing of each session can be either half an hour or one hour, based on the user's preference.",
    //cover: "../images/blog/b2.webp",
    cover: "../images/blog/ielts.jpg"
  },
  {
    id: 3,
    type: "Ph.D Bhavneet kaur",
    date: "MAY. 15, 2022",
    com: "MBA",
    title: "PTE Sessions",
    desc: "Each session lasts for 26 days, excluding Sundays. The timing of each session can be either half an hour or one hour, based on the user's preference.",
    //cover: "../images/blog/files.jpg",
   cover:"../images/blog/Speaking-Quick-Tips.jpg"
  },
  // {
  //   id: 4,
  //   type: "admin",
  //   date: "JAN. 02, 2022",
  //   com: "3 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b4.webp",
  // },
  // {
  //   id: 5,
  //   type: "admin",
  //   date: "DEC. 14, 2022",
  //   com: "3 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b5.webp",
  // },
  // {
  //   id: 6,
  //   type: "user",
  //   date: "JAN. 18, 2021",
  //   com: "12 COMMENTS ",
  //   title: "Build your Dream Software & Engineering Career",
  //   desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
  //   cover: "../images/blog/b6.webp",
  // },
]
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
]
