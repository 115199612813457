import React, { useState } from "react"
import "./signuphome.css"
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
 
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import {  useNavigate } from "react-router-dom";
import apiCall from "../../api/Requesthandler";
import { LOG_IN, RECOVER_PASSWORD, REQUEST_TYPE } from "../../api/apiConfig";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Head from "../common/header/Head";

const Signupcard = () => {
	const navigation = useNavigate();
	const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [passwordError, setPasswordError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePasswordChange = async (e) => {
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);


        apiCall(RECOVER_PASSWORD.recover,REQUEST_TYPE.POST,formData).then((res)=>{

            console.log(res, '-----------');
                if(res.status ==200){
                    console.log(res.response.data.message, 'res.response.data.message');
            //toast(res.response.data.message)
            Swal.fire({
                position: "center",
                icon: "success",
                title: res.response.data.message,
                showConfirmButton: false,
                timer: 5000
              });
        //     Cookies.set('userId',res.response.data.data.userId)
        //     Cookies.set('access_token',res.response.data.data.token)

        //   setTimeout(() => {
        //     navigation(`/`, {replace:true})
        //     window.location.reload();
        //   }, 1000);
               
          


        }
        else {
            console.log(res,'hi');
            console.log(res.response.response);
            //toast(res.response.response.data.message)
            Swal.fire({
                position: "center",
                icon: "error",
                title: res.response.response.data.message,
                showConfirmButton: false,
                timer: 3500
              });
        }

        })
    };

  return (
    <>
    <Head/>
	<div className="signup-container">
    <h2 className="signup-title">Recover your password!</h2>
        <form onSubmit={handleSubmit} className="signup-form">
            {/* <h2>Enter Your Email Address</h2>
            <div class="form-control">
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}  required/>
            </div> */}
            <input type="email" placeholder="Your email" className="signup-input" name="email" value={formData.email} onChange={handleChange}  required />

            <button className="signup-button" type="submit">Recover Password</button>
        </form>


    
	</div>
	<ToastContainer/>
    </>
  )
}

export default Signupcard
