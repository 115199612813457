import React, { useEffect, useRef } from "react"
import Back from "../common/back/Back"
import PriceCard from "./PriceCard"
import "./price.css"
import Faq from "./Faq"
import Header from "../common/header/Header"

const Pricing = () => {
 
  const componentRef = useRef(null);
//   useEffect(() => {
//     if (componentRef.current) {
// componentRef.current.scrollIntoView({ behavior: 'smooth' });
// }
// }, []);
  return (
    <>
 
      {/* <Back title='Choose The Right Plan' /> */}

       <section className='price padding' ref={componentRef}>
      <Faq />
      </section>
    </>
  )
}

export default Pricing
