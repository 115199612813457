import React from "react"
import Heading from "../../common/heading/Heading"
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import "./Hero.css"

const Hero = () => {
  const navigation = useNavigate();
  const SubmitButton = styled.button`
    box-shadow: none;
    border: 2px solid #1eb2a6;
    width: 50%;
    transition: 0.5s;
    margin-block: 10px;
    &:hover {
    background-color: #1eb2a6;
    color: #fff;
}
@media (max-width: 768px) {
  width: 70%; /* 100% width on mobile */
    }
`;

  const handleClick = async (t) => {
    console.log('Button clicked!', t);
    // Your custom logic goes here
    navigation('/Courses/english-spoken')
  }
  return (
    <>

      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading subtitle='WELCOME TO FLUENCIFY' title='Unlock Your Learning Potential with Fluencify' />
            <p>Discover, Learn, Thrive! Ignite your curiosity and unlock new horizons with our diverse range
              of courses.</p>
              <br></br>

            {/* <SubmitButton className='su-btn' type="button" onClick={navigation('/courses')}>
              Get started
            </SubmitButton> */}
            <div className='button' onClick={()=>handleClick('tes')}>
              <button className='primary-btn' >
              SPOKEN ENGLISH <i className='fa fa-long-arrow-alt-right'></i>
              </button>
            {/* <button>
                VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>
              </button> */}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Hero
