import React, { useEffect, useState } from 'react';
import './CourseDetails.css'; // Add custom styles here or use a CSS framework
import { useLocation, useNavigate } from 'react-router-dom';
import ScrollToTopOnRouteChange from '../scroll/ScrollToTopOnRouteChange';
import apiCall from '../../api/Requesthandler';
import { GET_COURSE_DETAIL, GET_USER, RAZORPAY, RAZORPAY_VERIFY, REQUEST_TYPE } from '../../api/apiConfig';
import Cookies from 'js-cookie';

const CourseDetails = () => {
  const navigation = useNavigate();
  const [activeTab, setActiveTab] = useState('Overview');
  const location = useLocation();
  const [advance, setAdvanceCourse] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const course = location.state?.course;


  useEffect(() => {

    if (course) {
      apiCall(GET_COURSE_DETAIL(course._id), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
        const idToCheck = res.response.data.data._id;
        console.log(idToCheck, '1');
        let content = [];
        if (idToCheck == '66aa9b30a570f3bb5340c82d' || idToCheck == '66eff2473b00ef8e394ce0e6') {
          setAdvanceCourse(true);
          content = res.response.data.data.topic;
        } else {
          content = res.response.data.data.content;
        }
        console.log(content, 'ccccccccccccccccccccccccccc');

        // setUser(res.response.data.data)
        setCourseData(content)





      })
    }


  }, [course])

  if (!course) {
    return <div>No course data available</div>;
  }




  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }



  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  async function showRazorpay(t) {
    const encodedData = btoa(JSON.stringify(t._id));
    console.log(t._id, 'ttttt',);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const datas = {
      coursename: t.title,
      amount: t.price,
      description: t.description,
      image: t.image,
      userId: Cookies.get('userId'),
    }
    // const data = await fetch("http://localhost:1337/razorpay", {
    //   method: "POST",
    //   data :{
    //   coursename : t.title,
    //   amount: t.price,
    //   description : t.description,
    //   image: t.image
    //   }
    // }).then((t) => t.json());
    apiCall(RAZORPAY.razorpay, REQUEST_TYPE.POST, datas, Cookies.get('access_token')).then((res) => {

      console.log(res, 'res.response.data.data');

      const data = res.response.data.data;



      console.log('data');
      console.log(data);

      const options = {
        key: "rzp_test_aRKcZ7OzzaKSTC",
        currency: data.currency,
        amount: data.amount,
        order_id: data.id,
        name: t.title,
        description: t.description,
        image: t.image,
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          console.log('response');
          console.log(response);

          const paymentData = {
            razorpay_order_id: response?.razorpay_order_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_signature: response?.razorpay_signature,
            userId: Cookies.get('userId'),
            coursename: t.title,
            course_id: t._id,
            amount: t.price,
            userId: Cookies.get('userId'),

          }
          apiCall(RAZORPAY.verify, REQUEST_TYPE.POST, paymentData, Cookies.get('access_token')).then((res) => {

            console.log(res?.response.data.message, "Payment Successfull");
            if (res?.response?.data?.message == "Payment Successfull") {


              navigation(`/CourseHome/${encodedData}`);
            } else {
              alert("Transaction unsuccessful");
            }

          })

        },
        prefill: {
          name: "Rajat",
          email: "rajat@rajat.com",
          phone_number: "9899999999",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    })
  }
  const handleClick = async (t) => {

    console.log('Button clicked!', t);
    const encodedData = btoa(JSON.stringify(t._id));
    // Your custom logic goes here



    const userId = Cookies.get('userId');

    if (userId !== undefined) {
      apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {

        console.log(res.hasError, 'reseee');
        if (t?.enroll && !res.hasError) {
          navigation(`/CourseHome/${encodedData}`);
        } else if (!t?.enroll && !res.hasError) {
          showRazorpay(t)
        } else {
          navigation(`/login`);
        }
      })

    } else {
      navigation(`/login`);
    }





  }

  const handleToggle = () => {
    setShowAll((prev) => !prev); // Toggle between showing all and showing 10
  };
  return (
    <>
      <ScrollToTopOnRouteChange />

      <div className="course-details-container">
        <div className="course-content">
          {/* Left Column */}
          <div className="course-details-left">
            {/* Course Header */}
            <div className="course-header">
              <h1>{course.title}</h1>
              <div className="course-meta">
                {/* <span>By {course.instructor}</span> */}
                <span>• {course.level}</span>
                {/* <span>• ★★★★★ ({course.reviewsCount} Reviews)</span> */}
              </div>
            </div>

            {/* Tabs */}
            <div className="course-tabs">
              <span
                className={activeTab === 'Overview' ? 'active-tab' : ''}
                onClick={() => handleTabClick('Overview')}
              >
                Overview
              </span>
              <span
                className={activeTab === 'Curriculum' ? 'active-tab' : ''}
                onClick={() => handleTabClick('Curriculum')}
              >
                Curriculum
              </span>
              <span
                className={activeTab === 'Instructor' ? 'active-tab' : ''}
                onClick={() => handleTabClick('Instructor')}
              >
                Instructor
              </span>
              {/* <span 
              className={activeTab === 'Reviews' ? 'active-tab' : ''} 
              onClick={() => handleTabClick('Reviews')}
            >
              Reviews
            </span> */}
            </div>

            {/* Conditional Rendering for Tabs */}
            <div className="tab-content">
              {activeTab === 'Overview' && (
                <div className="course-description">
                  <h2>Course Description</h2>
                  <p>{course.description}</p>
                </div>
              )}
              {activeTab === 'Curriculum' && (
               <div className="course-curriculum">
               <h2>Curriculum</h2>
               <ul>
                 {/* Display only the first 10 items or all items based on state */}
                 {courseData?.slice(0, showAll ? courseData.length : 10).map((course, index) => (
                   <li key={index}>{course.title}</li>
                 ))}
               </ul>
               
               {/* Button to toggle between showing all items or just 10 */}
               {courseData.length > 10 && (
                 <button className='outline-btn'  style={{width:"40%"}} onClick={handleToggle}>
                   {showAll ? 'Show Less' : 'Show More'}
                 </button>
               )}
             </div>
              )}
              {activeTab === 'Instructor' && (
                <div className="course-instructor">
                  <h2>Instructor Information</h2>
                  {/* <img 
              className="course-thumbnail"
              src={course.image} // Dynamic image URL
              alt="Course Thumbnail"
            /> */}
                  <p>{course.instructorBio}• Admin</p>
                </div>
              )}
              {activeTab === 'Reviews' && (
                <div className="course-reviews">
                  <h2>Reviews</h2>
                  <p>{course.reviews} No Reviews</p>
                </div>
              )}
            </div>
          </div>

          {/* Right Sidebar */}
          <div className="course-sidebar">
            {/* Price */}
            <div className="course-price">
              <img
                className="course-thumbnail"
                src={course.image} // Dynamic image URL
                alt="Course Thumbnail"
              />
              <div className="price-section">
                <span className="current-price">₹{course.price}</span>
                {course.originalPrice && (
                  <>
                    <span className="original-price">${course.originalPrice}</span>
                    <span className="discount">({course.discount}% OFF)</span>
                  </>
                )}
              </div>
            </div>

            {/* Course Details */}
            <div className="course-info">
              <div><strong>Level:</strong> {course.level}</div>
              {/* <div><strong>Lectures:</strong> {course.lecturesCount}</div> */}
              <div><strong>Students:</strong> {course.numberOfEnrollment}</div>
              <div><strong>Duration:</strong> {course.lengthInDay} Days</div>
              {/* <div><strong>Language:</strong> {course.language}</div> */}
              <div><strong>Updated:</strong> {course.published}</div>
            </div>

            {/* Enroll Button */}
            <button className='outline-btn' onClick={(e) => {
              e.stopPropagation(); // This will prevent the div's onClick from firing
              handleClick(course);
            }}>   {course.enroll ? 'GO To The Course' : 'ENROLL NOW !'}</button>

            {/* Material Includes */}
            {/* <div className="course-material">
            <h3>Material Includes</h3>
            <ul>
              {course.materials.map((material, index) => (
                <li key={index}>{material}</li>
              ))}
            </ul>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
