import React, { useEffect, useRef, useState } from "react"
import Back from "../common/back/Back"
import CoursesCard from "./CoursesCard"
import OnlineCourses from "./OnlineCourses"
import Header from "../common/header/Header"
import {  useParams } from "react-router-dom"
import {  GET_COURSE_DETAIL, GET_COURSE_ENROLLMENT, REQUEST_TYPE } from "../../api/apiConfig"
import apiCall from "../../api/Requesthandler"
import Cookies from 'js-cookie';
import Loader from "../Loader/loader"
import { useNavigate } from "react-router-dom";
import ScrollToTopOnRouteChange from "../scroll/ScrollToTopOnRouteChange"
const CourseHome = () => {
  const { courseid } = useParams();

  const [isDataLoading, setIsDataLoading] = useState(false);
 const [advance, setAdvanceCourse]=useState(false);
  const [courseData, setCourseData] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    setIsDataLoading(true)
    console.log('2222222222222222', courseid);
    const decodedCourseID = atob(courseid);
    console.log(JSON.parse(decodedCourseID), 'decodedCourseID');
    const userId = Cookies.get('userId');
    if (userId !== undefined) {
      apiCall(GET_COURSE_DETAIL(JSON.parse(decodedCourseID)), REQUEST_TYPE.GET,{},Cookies.get('access_token')).then((res) => {
        const idToCheck = res.response.data.data._id;
        console.log(idToCheck , '1');
        let content =[];
        if(idToCheck=='66aa9b30a570f3bb5340c82d' || idToCheck=='66eff2473b00ef8e394ce0e6'){
          setAdvanceCourse(true);
           content = res.response.data.data.topic;
        }else{
           content = res.response.data.data.content;
        }

        apiCall(GET_COURSE_ENROLLMENT(Cookies.get('userId')), REQUEST_TYPE.GET,{},Cookies.get('access_token')).then((res) => {
          console.log(res.response.data.data,'enronn');
const enrolledCourses =res.response.data.data;

   // Step 3: Check if idToCheck exists in the enrolledCourses array
   const exists = enrolledCourses.some(course => course._id === idToCheck);

   console.log(`ID exists in enrolled courses: ${exists}`);
   if (exists) {
          // setUser(res.response.data.data)
          setCourseData(content)
          setIsDataLoading(false)
    
   }else{
    navigation(`/courses`)
   }

        })

  
      })

    }
  }, []);

  return (
    <>
     {/* <Header /> */}
      {/* <Back title={item} /> */}
      {/* <CoursesCard /> */}
      <ScrollToTopOnRouteChange />
      {!isDataLoading ? <OnlineCourses courseData={courseData} advance={advance} /> : 
       <Loader />
     
}
      {/* <VideoPlayer/> */}
    </>
  )
}

export default CourseHome
