
import React from 'react';

const AnswerComparison = ({ myAnswer, correctAnswer }) => {
  console.log(myAnswer, correctAnswer, 'myAnswer, correctAnswer');
  const compareAnswers = (myAnswer, correctAnswer) => {
    const result = [];
    let foundMistake = false;

    for (let i = 0; i < correctAnswer.length; i++) {
      if (myAnswer[i] !== correctAnswer[i]) {
        result.push(
          <span1 key={i} className="mistake">
            {myAnswer[i] || '_'}
          </span1>
        );
        foundMistake = true;
      } else {
        result.push(myAnswer[i]);
      }
    }

    // If myAnswer is longer than correctAnswer, highlight the extra part
    if (myAnswer.length > correctAnswer.length) {
      for (let i = correctAnswer.length; i < myAnswer.length; i++) {
        result.push(
          <span1 key={i} className="mistake">
            {myAnswer[i]}
          </span1>
        );
        foundMistake = true;
      }
    }

    // If myAnswer is shorter than correctAnswer, add red underscores
    if (myAnswer.length < correctAnswer.length) {
      for (let i = myAnswer.length; i < correctAnswer.length; i++) {
        result.push(
          <span1 key={i} className="mistake">
            _
          </span1>
        );
        foundMistake = true;
      }
    }

    if (foundMistake) {
      return result;
    } else {
      return <span1 className='correct'>{myAnswer}</span1>;
    }
  };

  return <>{compareAnswers(myAnswer, correctAnswer)}</>
};

export default AnswerComparison;
