// src/components/Categories.js
import React from 'react';
import './Categories.css';
import { useNavigate } from "react-router-dom";
import PeopleIcon from '@mui/icons-material/People';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AssistantIcon from '@mui/icons-material/Assistant';



const Categories = () => {
  const navigation = useNavigate();

  const categories = [
    { id: 1,key:'/joinChat', name: 'Practice with Real People', color: '#E91E63', icon: <PeopleIcon style={{ color: 'black', fontSize: '40px' }} /> },
  //   { id: 2, name: 'Artificial Intelligence', color: '#009688', icon: 'icon-path-here' },
  //   { id: 3, name: 'Algebra Math Calculation', color: '#3F51B5',FF9800 icon: 'icon-path-here' }, // color updated to match the image design
    { id: 4, key:'/courses/english-spoken',name: 'English Spoken', color: '#9C27B0', icon:   <ChatBubbleIcon style={{ color: 'black',fontSize: 40 }} /> },
    { id: 5,key: '/courses/ielts', name: 'AI-Powered IELTS English Tutor', color: '#FF9800', icon:  <AssistantIcon style={{color: 'black', fontSize: 40 }} /> },
  ];
  
  const handleClick = async ( c) => {
    console.log('df', c);
    
    navigation(c.key)
    // navigation(t.key)
    
  }
  return (
    <div className="categories-container">
      <div className="categories-grid">
        {categories.map((category) => (
          <div
            key={category.id}
            className="category-card"
            style={{ backgroundColor: category.color }}
            onClick={() => handleClick(category)}
          >
            <div className="category-icon"
           
            >

              {/* <img src={category.icon} alt={category.name} /> */}
              {category.icon}
            </div>
            <h3>{category.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
