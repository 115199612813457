const SERVER_LINK = 'https://www.api.fluencifyai.com/api/';
//const SERVER_LINK = 'http://localhost:8888/api/'; 

export const REQUEST_TYPE = {

    GET : "GET",
    POST: "POST",
    PUT:"PUT", 
    DELETE:"DELETE"
}

export const SIGN_UP = {
    signup: `${SERVER_LINK}signUp`
}

export const LOG_IN = {
    login: `${SERVER_LINK}login`
}
export const RECOVER_PASSWORD = {
    recover: `${SERVER_LINK}user/forgot/password`
}

        
export const ACTIVATE_USER = {
    activate: `${SERVER_LINK}user/activate`
}


export const GET_USER=(id) => {
    return `${SERVER_LINK}user/${id}`
}

export const GET_COURSES = {
    course: `${SERVER_LINK}course`
}

export const RAZORPAY  = {
    razorpay: `${SERVER_LINK}razorpay`,
    verify: `${SERVER_LINK}razorpay/verify`
}

// export const RAZORPAY_VERIFY  = {
//     verify: `${SERVER_LINK}razorpay/verify`
// }





export const GET_USERS = {
    users: `${SERVER_LINK}user`
}

export const GET_ANSWER = {
    answer: `${SERVER_LINK}answer`
}

export const GET_COURSE_DETAIL =(id) =>{
    return `${SERVER_LINK}course/${id}`
}

export const GET_COURSE_ENROLLMENT =(id) =>{
    return `${SERVER_LINK}enrollment/user/${id}`
}



export const GET_CONTENT_DETAIL =(id) =>{
    return `${SERVER_LINK}content/${id}`
}



export const GET_LEVEL_DETAIL =(id) =>{
    return `${SERVER_LINK}level/${id}`
}


export const GET_DAY_DETAIL =(id) =>{
    return `${SERVER_LINK}day/${id}`
}

export const GET_TOPIC_DETAIL =(id) =>{
    return `${SERVER_LINK}topic/${id}`
}


export const GET_PROGRESS = {
    progress: `${SERVER_LINK}progress/id`
}
