import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { Provider } from 'react-redux';
import { SocketContext, socket } from './context/socket';
import { store } from './chatApp/store';



const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
     <Provider store={store}>
     <SocketContext.Provider value={socket}>
    <App />
    </SocketContext.Provider>
  </Provider>
  </React.StrictMode>
)
