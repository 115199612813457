import React, { useEffect, useRef, useState } from "react"
import { online4 } from "../../dummydata";
import ReactPlayer from 'react-player'
import "./leveldays.css"
import Swal from 'sweetalert2';
// import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from './ProgressBar';
import AnswerComparison from './AnswerComparison';
import Hero from "../home/hero/Hero";
import videoLogo from "../../assets/videoLogo.png"
import Head from "../common/header/Head";
import ReactDOMServer from 'react-dom/server';
import { useParams } from "react-router-dom";
import apiCall from "../../api/Requesthandler";
import { useNavigate } from "react-router-dom";
import { GET_ANSWER, GET_COURSE_ENROLLMENT, GET_DAY_DETAIL, GET_LEVEL_DETAIL, GET_PROGRESS, GET_TOPIC_DETAIL, REQUEST_TYPE } from "../../api/apiConfig";
import Cookies from 'js-cookie';
import Loader from "../Loader/loader";
import Modal from 'react-modal';
import ScrollToTopOnRouteChange from "../scroll/ScrollToTopOnRouteChange";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
// const SpeechRecognition =
//   window.SpeechRecognition || window.webkitSpeechRecognition
// const mic = new SpeechRecognition()

// mic.continuous = true
// mic.interimResults = true
// mic.lang = 'en-US'

const LevelDays = () => {
  const navigation = useNavigate();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [ved, setVed] = useState(null)
  const [openIndex, setOpenIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [isListening, setIsListening] = useState(false)
  const [isPlaying, setIsPlaying] = useState(true)
  //const [note, setNote] = useState(null)
  const [savedNotes, setSavedNotes] = useState([])
  const [model1, setmodel1] = useState(true);
  const [selectedItem, setSelectedItem] = useState(0);
  const [dayIndex, setDayIndex] = useState(null);
  const [savedNotesAns, setSavedNotesAns] = useState("");
  const [savedNotesAnswer, setSavedNotesAnswer] = useState("");
  const [isNext, setNext] = useState(true)
  const playerRef = useRef(null);
  const [ansSave, setansSave] = useState(false)
  const [daystData, setdaystData] = useState([])
  const [vedData, setVedData] = useState(null)
  const [dayName, setDayName] = useState('')
  const [videos, setVideos] = useState([])
  const [courseID, setCourseID] = useState('')
  const [progess, setProgress] = useState(0)
  const [isOpenModel, setIsOpenModel] = useState(true)
  const { levelId, courseName } = useParams();
  const [currentStep, setCurrentStep] = useState(0);


  let modelSteps = [
    {
      heading: `Welcome to ${courseName}.`,
      text: "This course focuses on developing fluency, pronunciation, vocabulary, and grammar for the IELTS Speaking test. You'll practice speaking on common topics, improve clarity, and boost confidence through mock interviews and personalized feedback. Perfect for mastering the test format and speaking naturally in English.",
    },
    {
      heading: "How to Start?",
      text: "The avatar asks a question, you speak your answer and submit it, then the next question appears. After completing all questions, you'll see your band score.",
    },
    {
      heading: "Restart Answer and Progress of Topic.",
      text: "To restart your answer, double-click the mic. The progress of the topic will be displayed at the top when you enter the course room.",
    },
  ];

  if (courseName == 'advanced_spoken_english') {
    modelSteps = [
      {
        heading: `Welcome to Advanced Spoken English.`,
        text: "This course helps you refine your fluency, pronunciation, vocabulary, and grammar for advanced communication. You’ll practice speaking on a range of topics, enhance clarity, and build confidence through engaging discussions and personalized feedback. Ideal for mastering conversational English and achieving natural, effective communication.",
      },
      {
        heading: "How to Start?",
        text: "The avatar asks a question, you speak your answer and submit it. You will see any mistakes in your sentence and the AI will show the corrected version. If your answer is correct, the next question appears automatically.",
      },
      {
        heading: "Restart Answer and Progress of Topic.",
        text: "If your answer is incorrect, double-click the mic before submitting to restart. You can also click the redo button after reviewing your mistakes to try again. The progress of the topic will be displayed at the top when you enter the course room.",
      },
    ];

  } else if (courseName == 'basic_spoken_english') {
    modelSteps = [
      {
        heading: `Welcome to Basic Spoken English.`,
        text: "This course helps you improve your basic fluency, pronunciation, vocabulary, and grammar for everyday communication. You'll practice speaking on simple topics, learn how to construct clear sentences, and build confidence through guided exercises and feedback. Perfect for beginners aiming to master the basics of conversational English and communicate effectively in daily situations.",
      },
      {
        heading: "How to Start?",
        text: "The avatar asks a question, you speak your answer and submit it. You will see any mistakes in your sentence and the AI will show the corrected version. If your answer is correct, the next question appears automatically.",
      },
      {
        heading: "Restart Answer and Progress of Topic.",
        text: "If your answer is incorrect, double-click the mic before submitting to restart. You can also click the redo button after reviewing your mistakes to try again. The progress of the topic will be displayed at the top when you enter the course room.",
      },
      {
        heading: "Response Options.",
        text: `Users have two options to respond: one for a positive answer and one for a negative answer. Click the buttons at the top: A for "Yes"(default) and B for "No".`,
      },
    ];

  } else if (courseName == 'intermediate_spoken_english') {
    modelSteps = [
      {
        heading: `Welcome to Intermediate Spoken English.`,
        text: "This course helps you enhance your fluency, pronunciation, vocabulary, and grammar for more complex communication. You'll practice speaking on a variety of topics, improve sentence structure, and build confidence through interactive discussions and constructive feedback. Ideal for learners looking to elevate their conversational skills and communicate effectively in a range of situations.",
      },
      {
        heading: "How to Start?",
        text: "The avatar asks a question, you speak your answer and submit it. You will see any mistakes in your sentence and the AI will show the corrected version. If your answer is correct, the next question appears automatically.",
      },
      {
        heading: "Restart Answer and Progress of Topic.",
        text: "If your answer is incorrect, double-click the mic  before submitting to restart. You can also click the redo button after reviewing your mistakes to try again. The progress of the topic will be displayed at the top when you enter the course room.",
      },
      {
        heading: "Response Options.",
        text: `Users have two options to respond: one for a positive answer and one for a negative answer. Click the buttons at the top: A for "Yes"(default) and B for "No".`,
      },
    ];

  }

  const nextStep = () => setCurrentStep((prevStep) => prevStep + 1);
  const preStep = () => setCurrentStep((prevStep) => prevStep - 1);

  useEffect(() => {


    setIsDataLoading(true)

    const decodedlevelId = atob(levelId);


    const userId = Cookies.get('userId');
    if (userId !== undefined) {
      apiCall((courseName == 'advanced_spoken_english' || courseName == 'Ielts Spoken English') ? GET_TOPIC_DETAIL(JSON.parse(decodedlevelId)) : GET_DAY_DETAIL(JSON.parse(decodedlevelId)), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {

        console.log(res.response.data.data.video, 'GET_DAY_DETAIL');
        const idToCheck = res.response.data.data.courseName;
        console.log(idToCheck, '1');
        const videos = res.response.data.data.video;


        apiCall(GET_COURSE_ENROLLMENT(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
          console.log(res.response.data.data, 'enronn');
          const enrolledCourses = res.response.data.data;

          // Step 3: Check if idToCheck exists in the enrolledCourses array
          const exists = enrolledCourses.some(course => course.name === idToCheck);

          console.log(`ID exists in enrolled courses: ${exists}`);
          if (exists) {
            const course = enrolledCourses.find(course => course.name === idToCheck);
            setCourseID(course._id)


            const payload = {
              "userId": userId,
              "courseId": course._id
            }
            apiCall(GET_PROGRESS.progress, REQUEST_TYPE.POST, payload, Cookies.get('access_token')).then((res) => {
              const progessVideos = res?.response?.data?.data[0]?.video;
              setdaystData([{ lable: "A for Yes", value: "yes" }, { lable: "B of No", value: "no" }])
              const filteredArr = videos.filter(item => !progessVideos?.includes(item._id));
              console.log(filteredArr, 'filteredArr');
              setVideos(filteredArr)
              const idsInArr1 = new Set(videos.map(item => item._id));

              // Filter arr2 to only include IDs present in idsInArr1
              const filteredIds = progessVideos?.filter(id => idsInArr1.has(id));

              // Create a set from filteredIds to get unique IDs and get the count
              const uniqueFilteredIds = new Set(filteredIds);
              console.log(uniqueFilteredIds, 'uniqueFilteredIds', videos.length, 'videos.length ');

              console.log(uniqueFilteredIds.size, 'uniqueFilteredIds.size');
              const percentage = (uniqueFilteredIds.size / videos.length) * 100;
              setProgress(parseFloat(percentage.toFixed(2)))

              setIsDataLoading(false)


            })




          } else {
            navigation(`/courses`)
          }

        })

      })

    }

  }, []);


  const startTopic = () => {
    if (courseName == 'advanced_spoken_english' || courseName == 'Ielts Spoken English') {

      toggleNestedList(0, videos)

      setmodel1(true)
      setIsOpenModel(false)


    } else {
      setOpenIndex(0)
      toggleNestedList(0, videos);
      setIsOpenModel(false)
    }

  }

  const handleClose = () => {
    setansSave(false)
    console.log(openIndex, 'openIndex');
    setOpenIndex(openIndex)
    setSavedNotes('')
    //setNote('')
    resetTranscript()
    setShow(false)
  };
  const handleShow = () => setShow(true);



  useEffect(() => {
    handleListen()
  }, [isListening])

  const handleListen = () => {
    // if (isListening) {
    //   mic.start()
    //   mic.onend = () => {
    //     console.log('continue..')
    //     mic.start()
    //   }
    // } else {
    //   mic.stop()
    //   mic.onend = () => {
    //     console.log('Stopped Mic on Click')
    //   }
    // }
    // mic.onstart = () => {
    //   console.log('Mics on')

    // }

    // mic.onresult = event => {
    //   const transcript = Array.from(event.results)
    //     .map(result => result[0])
    //     .map(result => result.transcript)
    //     .join('')
    //   console.log(transcript)
    //   setNote(transcript)
    //   mic.onerror = event => {
    //     console.log(event.error)
    //   }
    // }
    if(isListening){
      SpeechRecognition.startListening({ continuous: true });
    }else{
      SpeechRecognition.stopListening();
      resetTranscript()
    }


  }

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <div>Browser doesn't support speech recognition.</div>;
  }

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
    setIsListening(true);
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
  };
  const getFirstTwoWords = (str) => {
    const words = str.split(" ");
    return words.slice(0, 2).join(" ");
  };

  const toggleNestedList = (selectedItem, videos) => {
    //setNote('')
    resetTranscript()
    setIsListening(false)
    console.log(selectedItem, 'toggleNestedList(0)');
    let selectedItemData = videos[selectedItem];
    console.log(selectedItemData, ' ');

    if (selectedItemData) {

      console.log(selectedItemData.link);
      setVed(selectedItemData.link);
      setIsPlaying(true)
      setSavedNotesAns(selectedItemData._id)
      setSavedNotesAnswer(selectedItemData.answer)
      console.log('h');
      setSelectedItem(selectedItem)
      // setTimeout(() => {
      //   setIsListening(true)
      // }, 1000);

    } else {
      console.log('errrrrrrrrrr!');
    }


  };



  const handleVideoEnd = () => {
    console.log('ended');
    setDayName(videos[selectedItem].title)
    handleShow()
    setIsListening(true)

    // Video playback has ended, so show the popup
    // setShowPopup(true);
  };





  // const handleModel = () => {
  //   setansSave(false)
  //   console.log('id', note, "ttttttttc=>", savedNotesAns);


  //   const componentHtml = ReactDOMServer.renderToString(
  //     <AnswerComparison myAnswer={note} correctAnswer={savedNotesAns[openIndex]} />
  //   );
  //   console.log(savedNotesAns[openIndex].replace(/[,.]/g, '').toLowerCase(), 'avedNotesAns[0].replace(/[,.]/g,');
  //   if (note.toLowerCase() == savedNotesAns[openIndex].replace(/[,.]/g, '').toLowerCase()) {
  //     setmodel1(true)
  //     Swal.fire({
  //       position: "top-end",
  //       icon: "success",
  //       title: "Correct Answer",
  //       html: `
  //   <div>
  //     <p>Your answer:<span2> ${note}</span2></p>
  //     <p>Correct Answer: <span class="correct">${savedNotesAns[openIndex]}</span></p>
  //   </div>
  // `,
  //       showConfirmButton: false,
  //       timer: 1000
  //     });
  //     setTimeout(() => {
  //       handleModel2()

  //     }, 1500);
  //     setDayName('')

  //   } else {
  //     Swal.fire({ 
  //       position: "top-end",
  //       icon: "error",
  //       title: "Wrong Answer",
  //       html: `
  //   <div>
  //     <p>Your answer:  ${componentHtml}</span2></p>
  //     <p>Correct Answer: <span class="correct">${savedNotesAns[openIndex]}</span></p>
  //   </div>
  // `,
  //       showConfirmButton: false,
  //       timer: 3500
  //     });
  //   }

  //   setmodel1(false)
  //   setVed('')
  //   setDayName('')


  // };


  const handleModel = () => {
   // setIsDataLoading(true)
    setansSave(false)
    setIsListening(false)
    setIsPlaying(false)
    console.log('id', transcript, "ttttttttc=>", savedNotesAns);
    let payload = {
      userId: Cookies.get('userId'),
      courseId: courseID,
      videoId: savedNotesAns,
      "answer": transcript,
      "answertype":openIndex==0?'yes':'no',
      "topic":atob(levelId).replaceAll('"','')
    }
    console.log(payload, 'payload');
    apiCall(GET_ANSWER.answer, REQUEST_TYPE.POST, payload, Cookies.get('access_token')).then((res) => {
      console.log(res, 'ansssssssswrrr');
      if (res.status == 200) {
        setIsDataLoading(false)
        let answerData = res.response.data; //response //mistakes "response": "CORRECT",
        if (answerData.response == 'INCORRECT' && courseID == '66aa9b30a570f3bb5340c82d') {
          //let str = answerData.mistakes.replace(/\n/g, ' ');

          let lines = answerData.mistakes.split('\n');
          console.log(lines, 'lines');
          // Initialize the array to hold the objects
          let corrections = [];
          let error = "";
          let correction = "";
          function cleanText(text) {
            return text.replace(/^"|"$/g, ''); // Removes quotes at the beginning and end
          }

          // Iterate through each line and construct objects
          lines.forEach(line => {
            if (line.startsWith('Error:')) {
              if (error && correction) {
                corrections.push({ error: cleanText(error), correction: cleanText(correction) });
                error = "";
                correction = "";
              }
              error = line.replace('Error: ', '');
            } else if (line.startsWith('Correction:')) {
              correction = line.replace('Correction: ', '');
            } else if (line.includes(':')) {
              // Handle any other label for the final corrected text
              let [label, text] = line.split(':', 2);
              if (text) {
                corrections.push({ error: transcript, correction: cleanText(text) });
              }
            }
          });

          // Push the last pair if it exists
          if (error && correction) {
            corrections.push({ error: cleanText(error), correction: cleanText(correction) });
          }

          console.log(corrections);

          let htmlContent = `  <table class="table">
        <thead>
            <tr>
                <th>Mistakes</th>
                <th>Corrections</th>
            </tr>
        </thead>
        <tbody>
            `;
          for (let i = 0; i < corrections.length; i++) {
            htmlContent += `
   <tr>
                <td class="mistake">${corrections[i].error}</td>
                <td class="correction">${corrections[i].correction}</td>
            </tr>
  `;
          }
          htmlContent += `  </tbody>
    </table>`;

          Swal.fire({
            position: "top-end",
            title: "Sentence Corrections",
            html: htmlContent,
            showConfirmButton: true, // Show confirm button for manual closure
            confirmButtonText: 'OK', // Customize the text of the confirm button
            didClose: () => {
              // Optional: Add any cleanup or additional logic here
              console.log('Alert closed');
              setmodel1(false)
              //setVed('')
              setDayName('')

            }
          });

        } else if (answerData.response == 'INCORRECT' && courseID !== '66aa9b30a570f3bb5340c82d') {

          const componentHtmls = ReactDOMServer.renderToString(
            <AnswerComparison myAnswer={transcript} correctAnswer={savedNotesAnswer[openIndex]} />
          );
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Wrong Answer",
            html: `
    <div>
      <p>Your answer:  ${componentHtmls}</span2></p>
      <p>Correct Answer: <span class="correct">${savedNotesAnswer[openIndex]}</span></p>
    </div>
  `,
            showConfirmButton: true, // Show confirm button for manual closure
            confirmButtonText: 'OK',
            didClose: () => {
              // Optional: Add any cleanup or additional logic here
              console.log('Alert closed');
              setmodel1(false)
              //setVed('')
              setDayName('')

            }
          });

        } else {
          console.log('correcttttttttt');
          setmodel1(true)
          if(courseName == 'Ielts Spoken English'){
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Answer Submited!!",
              html: `
      <div>
        <p>Your answer:<span2> ${transcript}</span2></p>
      </div>
    `,
              showConfirmButton: false,
              timer: 2000
            });

          }else{
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Correct Answer",
              html: `
      <div>
        <p>Your answer:<span2> ${transcript}</span2></p>
        <p>Correct Answer: <span class="correct">${transcript}</span></p>
      </div>
    `,
              showConfirmButton: false,
              timer: 1000
            });
          }
       

          setTimeout(() => {
            handleModel2()

          }, 1500);
          setDayName('')

        }
      } else {
        setIsDataLoading(false)
      }

    })



  };
  const handleModel2 = () => {
    setDayName('')
    setIsListening(true)
    setIsPlaying(true)
    toggleNestedList(selectedItem + 1, videos)
    setmodel1(true)


  };

  const handleModel3 = () => {
    setIsListening(true)
    setIsPlaying(true)

    setVed('')
    toggleNestedList(selectedItem, videos)
    setmodel1(true)


  };

  // useEffect(() => {
  //   // Additional actions to perform on component mount
  //   // For example, loading data or any other initialization
  //   handleItemClick(0); // Call handleItemClick for the first list item when the component is mounted
  // }, []);

  const handleItemClick = (itemIndex) => {
    setSelectedItem(itemIndex);
    // Handle other click actions here if needed
    const selectedItemData = online4[openIndex]?.data[itemIndex];
    if (selectedItemData) {
      console.log(selectedItemData.link);
      setVed(selectedItemData.link);
      console.log('h');
    }
  }

  const handleSkip = () => {
    console.log('skiped');
    toggleNestedList(dayIndex, selectedItem + 1, vedData)
  }
  return (

    <>
     <ScrollToTopOnRouteChange />

      {isDataLoading ? <Loader /> :


        <section className='online'>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet" />

          {courseName == 'advanced_spoken_english' || courseName == 'Ielts Spoken English' ? <></> :
            <div className="scrollable-wrapper">
              {daystData.map((val, index) => (
                <div className="" key={index}>
                  <button className={`listbtn ${openIndex === index ? 'active' : ''}`} onClick={() => {
                    setOpenIndex(index)
                    toggleNestedList(0, videos);
                    setmodel1(true)

                  }}

                  >
                    <p>{val.lable}</p>
                    {/* <span>{openIndex === index ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-right"></i>}</span> */}
                  </button>

                </div>
              ))}
            </div>
          }
          <ProgressBar
            id="linear"
            type="Linear"
            height="20px"
            value={progess}
            animation={{
              enable: true,
              duration: 2000,
              delay: 0,
            }}
          />




          <div className="videoplayer" >
            <div className="dayTitle">
              <h2>{dayName}</h2>
            </div>
            <button className="skip" onClick={handleModel2}>skip</button>
            {/* <div className="videoplayerLogo">
    <img src={videoLogo} alt="" />
   </div> */}


            <div className="videoplayer-box">
              {show && (<div className="answer-box">
                <div id="heading">
                  {/* <h3>Answer here</h3> */}
                </div>

                {(model1 && (
                  <>

                    {!ansSave && (
                      <>
                        <div className="container">
                          <div className="mic">
                            <div onClick={() => setIsListening(prevState => !prevState)}>
                              {listening ? <> <span className="mouse-icon">   <MicIcon /> </span> </> : <span className="mouse-icon1"> <MicOffIcon /></span>}
                            </div>
                          </div>

                        </div>

                        <h1 className="noteText">{transcript}</h1>
                      </>
                    )}
                    {transcript && (
                      <>
                        <button variant="primary" className="vid-btn" onClick={() => { handleModel() }}>Submit</button>
                      </>
                    )}

                  </>
                ))}

                {(!model1 && (
                  <>

                    <div className="vid-btns">
                      <button className="vid-btn" onClick={handleModel3}>Redo</button>

                      <button className="vid-btn" onClick={handleModel2}>Next</button>
                    </div>
                  </>
                ))}



              </div>
              )}
              <ReactPlayer
                ref={playerRef}
                className="react-player"
                controls={false}
                volume={1}
                url={ved}//
                width="1300"
                pip={true}
                // height="100"
                playsinline 
                playing={isPlaying}
                onEnded={handleVideoEnd}
                // playbackRate={0.8} 
                // poster={videoLogo}
                //height='10'
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload', // Disable download for some browsers
                      disablePictureInPicture: true,
                      onContextMenu: e => e.preventDefault(), // Hide controls
                    }
                  }
                }}
              />

            </div>


          </div>

          <Modal
            isOpen={isOpenModel}
            // onRequestClose={closeModal}
            contentLabel="Form Modal"
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                border: 'none',
                padding: '0',
                maxWidth: '450px',
                width: '100%',
                overflow: 'visible',
              },
            }}
          >
            <div className="checkmodeel">

              {/* Dynamically render heading and text based on the current step */}
              <div>
                <h1>{modelSteps[currentStep].heading}</h1>
                <p>{modelSteps[currentStep].text}</p>
              </div>

              {/* Next button or Let's Start depending on the current step */}
              {currentStep < modelSteps.length - 1 ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: window.innerWidth <= 768 ? 'column' : 'row',

                    }}
                  >
                    <div>
                      <button className={currentStep > 0 ? "is-link" : ''}
                        onClick={preStep}
                        disabled={currentStep <= 0 ? true : false}
                        style={{
                          marginTop: '20px',
                          padding: '10px 20px',
                          fontSize: '16px',
                          cursor: 'pointer',
                          marginRight: '5px'
                        }}
                      >
                        Previous
                      </button>
                      <button className="is-link"
                        onClick={nextStep}
                        style={{
                          marginTop: '20px',
                          padding: '10px 20px',
                          fontSize: '16px',
                          cursor: 'pointer',
                        }}
                      >
                        Next
                      </button>
                    </div>

                    <button className="is-link"
                      onClick={startTopic}
                      style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      Skip to Start
                    </button>

                  </div>
                </>
              ) : (
                <button
                  className="is-link"
                  onClick={startTopic}
                  style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                >
                  Let's Start
                </button>
              )}
            </div>
          </Modal>

{videos.length==0 &&(
 
 <Modal
 isOpen={isOpenModel}
 // onRequestClose={closeModal}
 contentLabel="Form Modal"
 ariaHideApp={false}
 style={{
   overlay: {
     backgroundColor: 'rgba(0, 0, 0, 0.5)',
   },
   content: {
     top: '50%',
     left: '50%',
     right: 'auto',
     bottom: 'auto',
     marginRight: '-50%',
     transform: 'translate(-50%, -50%)',
     border: 'none',
     padding: '0',
     maxWidth: '450px',
     width: '100%',
     overflow: 'visible',
   },
 }}
>
 <div className="checkmodeel">

   {/* Dynamically render heading and text based on the current step */}
   <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f0f8ff', borderRadius: '10px' }}>
      <h1 style={{ color: '#4CAF50', fontSize: '2.5em', fontWeight: 'bold' }}>🎉 Congratulations!</h1>
      <h2 style={{ color: '#333' }}>You've Successfully Completed This Topic</h2>
      <p style={{ fontSize: '1.2em', color: '#555' }}>
        Amazing! You scored <strong>7 Bands</strong> in this IELTS Speaking course module.
      </p>
      <p style={{ fontSize: '1.1em', color: '#777' }}>
        Keep up the great work and continue your journey to higher scores!
      </p>
      <button
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          fontSize: '1em',
          cursor: 'pointer'
        }}
        onClick={() => alert('Keep learning!')}
      >
        Continue Learning
      </button>
    </div>

   {/* Next button or Let's Start depending on the current step */}

 </div>
</Modal>

)}

        </section>


      }
    </>
  )
}

export default LevelDays
// when video will complete a pop up will open in which react-mic will start in which user can give answer of that video
