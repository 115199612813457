import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '../../../../chatApp/hooks';
import Message from './Message';

const MessagesDisplay: React.FC = () => {
  const messages = useAppSelector(state => state.room.messages);

  const messageDisplayRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messageDisplayRef.current) {
      messageDisplayRef.current.scrollTop = messageDisplayRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div 
      id="messageDisplay" 
      ref={messageDisplayRef} 
      className="is-flex-grow-1 box is-flex is-flex-direction-column" 
    >
      {messages.map(message => (
        <Message message={message.content} key={message.id} className={message.className} />
      ))}
    </div>
  );
};

export default MessagesDisplay;
