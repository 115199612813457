import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { resetAppUser } from '../../../../util/middleware/appActions/user';
import { sendRemoveUser } from '../../../../services/socket/publishers';
import { useAppDispatch, useAppSelector } from '../../../../chatApp/hooks';
import { setChatVisbility } from '../../../../chatApp/features/roomSlice';
import { sendEndChat } from '../../../../services/socket/publishers';

const Navbar: React.FC = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const user = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();


  const handleLogout = () => {
    window.localStorage.removeItem('chat-username');
    resetAppUser();
    sendRemoveUser();
  };
  const room = useAppSelector(state => state.room);
  const isTextChatVisible = useAppSelector(state => state.room.isTextChatVisible);

  const handleEndClick = () => {
    sendEndChat(room.roomId);
    // dispatch(setIsBusy(false));
    // socket.emit('end chat', room.roomId);
  };

  const handleToggleChat = () => {
    dispatch(setChatVisbility(!isTextChatVisible));
  };


  return (
    <nav id="navbar" className="navbar is-fixed-top is-dark" role="navigation"  aria-label="main navigation">
      <div className="navbar-brand">
      <Link to="/courses" className="navbar-item has-text-centered">
            <span className="icon">
              <i className="fas fa-home p-1"></i>
            </span>
          </Link>

        {/* <div onClick={() => setIsMenuActive(!isMenuActive)} role="button" className={`${isMenuActive && 'is-active'} navbar-burger`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div> */}
      </div>
      <div className={`${isMenuActive && 'is-active'} navbar-menu`}>
        <div className="navbar-end">
       
          {/* <Link to="/about" className="navbar-item has-text-centered">
            <span className="icon">
              <i className="fas fa-info-circle p-1"></i>
            </span>
          </Link> */}
          {!user.isBusy && user.username
            ?
           
              <button className="outline-btn" onClick={handleLogout}>
                Leave 
              </button>
            :
            <>
            <button className="outline-btn" onClick={handleEndClick}>End Chat</button>
            <button className="outline-btn" onClick={handleToggleChat}>{isTextChatVisible ? 'Hide Chat' : 'Show Chat'}</button>
            </>
          }
        </div>
      </div>
    </nav>
  );
};

export default Navbar;