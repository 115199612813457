


// src/components/Login.js
//import React from "react";
//import "./Login.css";
import React, { useState } from "react"
import "./signuphome.css"
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
 
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import {  useNavigate } from "react-router-dom";
import apiCall from "../../api/Requesthandler";
import { LOG_IN, REQUEST_TYPE } from "../../api/apiConfig";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import Head from "../common/header/Head";

const Signupcard = () => {

    const navigation = useNavigate();
	const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [passwordError, setPasswordError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePasswordChange = async (e) => {
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log(formData);
 
        apiCall(LOG_IN.login,REQUEST_TYPE.POST,formData).then((res)=>{

            console.log(res, '-----------');
                if(res.status ==200){
                    console.log(res.response.data.message, 'res.response.data.message');
            //toast(res.response.data.message)
            Swal.fire({
                position: "center",
                icon: "success",
                title: res.response.data.message,
                showConfirmButton: false,
                timer: 2000
              });
            Cookies.set('userId',res.response.data.data.userId)
            Cookies.set('access_token',res.response.data.data.token)

          setTimeout(() => {
            navigation(`/`, {replace:true})
            window.location.reload();
          }, 1000);
               
          


        }
        else {
            console.log(res,'hi');
            console.log(res.response.response);
            //toast(res.response.response.data.message)
            Swal.fire({
                position: "center",
                icon: "error",
                title: res.response.response.data.message,
                showConfirmButton: false,
                timer: 3500
              });
        }

        })
    };
  return (
    <>
    <Head />
    <div className="login-container">
      <h2 className="login-title">Log in</h2>
      <div className="signup-links">
        <a href="/signUp">Sign up as a student</a>
      </div>
      {/* <div className="social-login">
        <button className="social-button google">Continue with Google</button>
        <button className="social-button facebook">Continue with Facebook</button>
        <button className="social-button apple">Continue with Apple</button>
      </div>
      <div className="or-divider">or</div> */}
      <div className="or-divider">or</div> 
      <form className="login-form" onSubmit={handleSubmit}>
        <input type="email" placeholder="Your email" name="email" className="login-input" value={formData.email} onChange={handleChange}  required />
        <input type="password" placeholder="Your password" name="password" className="login-input" value={formData.password} onChange={handleChange}  required />
        <a href="/recover_password" className="forgot-password">Forgot your password?</a>
        <div className="remember-me">
          <input type="checkbox" id="rememberMe" />
          <label htmlFor="rememberMe">Remember me</label>
        </div>
        <button type="submit" className="login-button">Log in</button>
      </form>
      <p className="terms">
        By clicking Log in or Continue with, you agree to Preply <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a>.
      </p>
    </div>
    	<ToastContainer/>
     </>
  );
};

export default Signupcard;

