import React from "react"
import { blog } from "../../../dummydata"
import "./footer.css"
import { Link } from "react-router-dom"
import logo from "../../../assets/AI.png"
const Footer = () => {
  const handleClick = () => {
    // Programmatically navigate to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      {/* <section className='newletter'>
        <div className='container flexSB'>
          <div className='left row'>
            <h1>Newsletter - Stay tune and get the latest update</h1>
            <span>Far far away, behind the word mountains</span>
          </div>
          <div className='right row'>
            <input type='text' placeholder='Enter email address' />
            <i className='fa fa-paper-plane'></i>
          </div>
        </div>
      </section> */}
      <footer>
        <div className='container padding'>
          <div className='box logo'>
            {/* <h1>FLUENCIFY</h1>
            <span>ONLINE EDUCATION & LEARNING</span>
            <p>Mastering IELTS opens doors to global opportunities, enhancing both career prospects and academic pursuits.</p> */}
           <img src={logo} alt="" />
           <br/>
           {/* <br/> */}
           {/* <br/> */}
           <div className="social-icons">
            <a href="https://www.instagram.com/fluencify.ai/" target='_blank'><i className='fab fa-instagram icon'></i></a>
            <a href="https://www.linkedin.com/in/fluencify-ai-1007372ba/" target='_blank'><i className='fab fa-linkedin-in icon'></i></a>
            <a href="#" target='_blank'><i className='fab fa-youtube icon'></i></a>
            </div>
          </div>
          <div className='box link'>
            <h3>Explore</h3>
            <ul>
            <Link to='/about' onClick={handleClick}><li>About Us</li></Link>  
              {/* <li>Services</li> */}
              <Link to='/courses' onClick={handleClick}> <li>Courses</li></Link>
              {/* <li>Blog</li> */ }
              <Link to='/experts' onClick={handleClick}><li>Experts</li></Link>
              <Link to='/contact' onClick={handleClick}><li>Contact Us</li></Link>
            </ul>
          </div>
          <div className='box link'>
            <h3>Quick Links</h3>
            <ul>
            <Link to='/contact' onClick={handleClick}><li>Contact Us</li></Link>
            <Link to='/FAQs' onClick={handleClick}><li>FAQs</li></Link>
              {/* <li>Terms & Conditions</li>
              <li>Privacy</li> */}
              {/* <li>Feedbacks</li> */}
            </ul>
          </div>
          <div className='box-query'>
            <h3>Help and Support</h3>
             <div className='help'>
            <input type='text' placeholder='Enter email address' />
            <i className='fa fa-paper-plane'></i>
          </div>
            {/* {blog.slice(0, 3).map((val) => (
              <div className='items flexSB'>
                <div className='img'>
                  <img src={val.cover} alt='' />
                </div>
                <div className='text'>
                  <span>
                    <i className='fa fa-calendar-alt'></i>
                    <label htmlFor=''>{val.date}</label>
                  </span>
                  <span>
                    <i className='fa fa-user'></i>
                    <label htmlFor=''>{val.type}</label>
                  </span>
                  <h4>{val.title.slice(0, 40)}...</h4>
                </div>
              </div>
            ))} */}
          </div>
          <div className='box last'>
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className='fa fa-map'></i>
                390-A Geen Avenue, Amritsar, India
              </li>
              <li>
                <i className='fa fa-phone-alt'></i>
                +91 87119 10000
              </li>
              <li>
                <i className='fa fa-paper-plane'></i>
                hr@fluencifyai.com
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright"> 
        <p>&copy;{new Date().getFullYear()} FluencifyAI. All rights reserved.</p>
        </div>
      </footer>
      {/* <div className='legal'>
        <p>
          Copyright ©2022 All rights reserved | This template is made with <i className='fa fa-heart'></i> by GorkhCoder
        </p>
      </div> */}
    </>
  )
}

export default Footer
