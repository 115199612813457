import React, {useCallback, useContext, useEffect, useState } from "react"
import Back from "../common/back/Back"
import CoursesCard from "./CoursesCard"
import Cookies from 'js-cookie';
import { GET_COURSES, GET_COURSE_ENROLLMENT, GET_USER, REQUEST_TYPE } from "../../api/apiConfig";
import { sendUpdateUserList, sendUserEntered, sendVideoInvite } from '../../services/socket/publishers';
import apiCall from "../../api/Requesthandler";
import Loader from "../Loader/loader";
import { useNavigate } from "react-router-dom";
import { SocketContext } from '../../context/socket';
import { useAppDispatch, useAppSelector } from '../../chatApp/hooks';
import { setActiveUsers } from '../../util/middleware/socketActions/activeUsers';
import { setUserId } from '../../util/middleware/socketActions/user';
import { sendEndChat } from '../../services/socket/publishers';
import { handleInviteRequested, handleInviteDeclined } from '../../util/middleware/socketActions/invite';
import { setAppNewUser } from '../../util/middleware/appActions/user';
import { setNewUser } from '../../chatApp/features/userSlice';
import ActiveUsers from '../chat/ActiveUsers';
import Layout from '../chat/Layout';
import { resetNotification } from '../../chatApp/features/notificationSlice';
import { RoomData } from '../../util/types';
import { resetRoom, setRoom } from '../../chatApp/features/roomSlice';
import { setIsBusy } from '../../chatApp/features/userSlice';
import { setNotificationChatClosed } from '../../util/middleware/appActions/notification';
import './courses.css'
//import 'bulma/css/bulma.min.css';
//import { sendUserEntered } from '../../services/socket/publishers';
const CourseHome = () => { 
  const navigation = useNavigate();
  const [courses, setCourses] = useState([]);
  const [userEnter, setUserEnter]= useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const dispatch = useAppDispatch();
  const socket = useContext(SocketContext);
  const currentUser = useAppSelector(state => state.user.socketId);
  const activeUsersUsernames = useAppSelector(state => state.activeUsers.users).map(user => user.username);
  const username = useAppSelector(state => state.user.username);
  const isUserBusy = useAppSelector(state => state.user.isBusy);
  const roomId = useAppSelector(state => state.room.roomId);
  

  if (isUserBusy) {
    sendEndChat(roomId);
  }
  const handleEnterChat = useCallback((roomData) => {
    console.log('handleEnterChat');
    sendUpdateUserList();
    dispatch(resetNotification());
    dispatch(setRoom({ roomId: roomData.roomId, users: roomData.users, isTextChatVisible: false, messages: [] }));
    navigation(`/p-room/${roomData.roomId}`);
    if (roomData.users[0] === currentUser) {
      sendVideoInvite();
    }
  }, [dispatch, navigation, currentUser]);
  const handleCloseChatRoom = useCallback(() => {
    console.log('handleCloseChatRoom');
    dispatch(setIsBusy(false));
    sendUpdateUserList();
    navigation('/joinChat');
    dispatch(resetRoom());
    setNotificationChatClosed();
  }, [navigation, dispatch]);

  useEffect(() => {
    console.log('=======================================');
    
    socket.once('connect', () => {
      console.log('Connected to server');
    });
    socket.on('get user list', setActiveUsers);
    socket.on('get socket id', setUserId);
    socket.on('invite requested', handleInviteRequested);
    socket.on('invite declined', handleInviteDeclined);
    socket.on('enter chat room', handleEnterChat);
    socket.on('close chat room', handleCloseChatRoom);

    return () => {
      socket.off('get user list', setActiveUsers);
      socket.off('get socket id', setUserId);
      socket.off('invite requested', handleInviteRequested);
      socket.off('invite declined', handleInviteDeclined);
      socket.off('enter chat room', handleEnterChat);
      socket.off('closeChatRoom', handleCloseChatRoom);
    };
  },
  [socket,
    handleEnterChat,
    handleCloseChatRoom]);

  useEffect(() => {

    setIsDataLoading(true)
    const userId = Cookies.get('userId');
 

        if (userId !== undefined && Cookies.get('access_token')) {
          apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
            console.log(res.response.data.data.firstName, 'rrrrrrrrrrrrrrrrrrrrrrr');
            
            if (res.hasError) {
              navigation('/login')
              setIsDataLoading(false)
            }else{
              setUserEnter(res.response.data.data.firstName)
              apiCall(GET_COURSE_ENROLLMENT(userId), REQUEST_TYPE.GET,{},Cookies.get('access_token')).then((res) => {
                console.log(res.response.data.data,'enronn');
      const enrolledCourses =res.response.data.data;  
      if (enrolledCourses?.length>0) {

        setCourses(enrolledCourses)
        setIsDataLoading(false)
       //sendUserEntered(Cookies.get('access_token'));
        //setAppNewUser(Cookies.get('access_token'));

        socket.once('connect', () => {
          console.log('Connected to server');
        });
        socket.on('get user list', setActiveUsers);
        socket.on('get socket id', setUserId);
        socket.on('invite requested', handleInviteRequested);
        socket.on('invite declined', handleInviteDeclined);
        socket.on('enter chat room', handleEnterChat);
        socket.on('close chat room', handleCloseChatRoom);
    
        return () => {
          socket.off('get user list', setActiveUsers);
          socket.off('get socket id', setUserId);
          socket.off('invite requested', handleInviteRequested);
          socket.off('invite declined', handleInviteDeclined);
          socket.off('enter chat room', handleEnterChat);
          socket.off('closeChatRoom', handleCloseChatRoom);
        };

      }else{
        navigation('/courses/all')
      }
    
                //const enrolledIds = new Set(enrolledCourses.map(course => course._id));
      
      //setIsDataLoading(false)
      
              })

            }

          }) 
      }else{
      //navigat
      navigation('/login')
      setIsDataLoading(false)
      
      }
        // setUser(res.response.data.data)
       
      

    
  }, []);

  const handleUsernameSubmit = (event) => {
   // event.preventDefault();
   console.log(event);
   console.log(userEnter, 'userEnter');
   
    // For demonstration application, the allowed usernames are `user1` and `user2`
    // To remove this limitation, move code outside of the if block and delete if block
    if ( Cookies.get('userId')) {
      //window.localStorage.setItem('chat-username', usernameInput);
      dispatch(setNewUser( userEnter));
      //setUsernameInput('');

      sendUserEntered( userEnter);
    }
  };

  return (
    <>
    {isDataLoading ? <Loader /> : 
<>
{!username
        ? <>
    {/* <Back title='All Courses' /> */}
    <Back title='All Courses Chat' />
    <CoursesCard course={courses} handleUsernameSubmit={handleUsernameSubmit}/>
</>
:
<div className="custom-navbar-wrapper">
<Layout>
        {/* <p id="welcome" className="is-size-5 has-text-centered">Welcome {username}</p> */}
        <ActiveUsers />
    </Layout>

</div>
  
}
    </>
  
  }

    

    </>
  )
}

export default CourseHome
