import React from "react"
import "./courses.css"
import { online2 } from "../../dummydata"
import Heading from "../common/heading/Heading"
import PriceCard from "../pricing/PriceCard"
import { useNavigate } from "react-router-dom";
import {cover} from '../..'
const OnlineCourses = ({courseData,advance} ) => {
  console.log(courseData, 'mnde', advance);
  const navigation = useNavigate();
 
  const handleSubmit= (val)=>{
    console.log(val._id);
    const encodedData = btoa(JSON.stringify(val._id));
    console.log(val.courseName, 'paaaaaaaaaaaajiiiiiiiiii');
    
    if (val.courseName=='advanced_spoken_english' ||  val.courseName=='Ielts Spoken English') {
      navigation(`/leveldays/${encodedData}/${val.courseName}`) 
    }else{
      navigation(`/lessons/${encodedData}`) 
    }
  


  }
  return (
    <>
      <section className='online'>
        <div className='container'>
          <Heading subtitle='LESSONS' title={''}/>
          {/* <div className='content grid3'>
            {online.map((val) => (
              <div className='box'>
                <div className='img'>
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt='' className='show' />
                </div>
                <h1>{val.courseName}</h1>
                <span>{val.course}</span>
              </div>
            ))}
          </div> */}


             <div className='grid'>
            {courseData?.map((val) => (
              <div className='box' onClick={()=>{
                handleSubmit(val)
              }}>
                <div className='img'>
                  {/* <img src={val.cover} />
                  <img src={val.hoverCover} alt='' className='show' /> */}
                 
                    {/* <img src=" https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmI5xjoAbT5bzYSF0-ahv0L8KnNEcBxcg58w&usqp=CAU" /> */}
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmI5xjoAbT5bzYSF0-ahv0L8KnNEcBxcg58w&usqp=CAU" alt='' className='show' />

                </div>
                <h1>{val.title}</h1>
                {/* <span>{val.course}</span> */}
              </div>
            ))}
          </div>
  <div className='container grid'>
          {/* <PriceCard /> */}
        </div>
          
        </div>
      </section>
    </>
  )
}

export default OnlineCourses
