// UserProfile.js

import React from 'react';
import './userprofile.css';

const UserProfile = ({user}) => {
    console.log(user,'firstName');   
  return (
<>
{user &&(
    <div className="main-content">
    {/* <!-- Top navbar --> */}
  
    {/* <!-- Header --> */}


    <div className="container-fluid mt--7">
      <div className="row">
        <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div className="card card-profile shadow">
            <div className="row justify-content-center">
              <div className="col-lg-3 order-lg-2">
                <div className="card-profile-image">
                  <a href="#">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrIMwQF5tiqO-E-rYuz7TT_tZ4ITeDzK3a-g&s" className="rounded-circle"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
              {/* <div className="d-flex justify-content-between">
                <a href="#" className="btn btn-sm btn-info mr-4">Connect</a>
                <a href="#" className="btn btn-sm btn-default float-right">Message</a>
              </div> */}
            </div>
            <div className="card-body pt-0 pt-md-4">
              <div className="row">
                {/* <div className="col">
                  <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                    <div>
                      <span className="heading">22</span>
                      <span className="description">Friends</span>
                    </div>
                    <div>
                      <span className="heading">10</span>
                      <span className="description">Photos</span>
                    </div>
                    <div>
                      <span className="heading">89</span>
                      <span className="description">Comments</span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="text-center">
                <h3>
                  {user.name}
                  {/* <span className="font-weight-light">, 27</span> */}
                </h3>
                {/* <div className="h5 font-weight-300">
                  <i className="ni location_pin mr-2"></i>Bucharest, Romania
                </div> */}
                {/* <div className="h5 mt-4">
                  <i className="ni business_briefcase-24 mr-2"></i>Solution Manager - Creative Tim Officer
                </div>
                <div>
                  <i className="ni education_hat mr-2"></i>University of Computer Science
                </div> */}
                <hr className="my-4"/>
                {/* <p>Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs and records all of his own music.</p>
                <a href="#">Show more</a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 order-xl-1">
          <div className="card bg-secondary shadow">
            <div className="card-header bg-white border-0">
              <div className="row align-items-center">
                <div className="col-8" id="heading">
                  <h3 className="mb-0">My account</h3>
                </div>
                <div className="text-right1">
                <button class="outline-btn">Settings</button>
           
                <button class="outline-btn">Edit</button>
                
                </div>
              </div>
            </div>
            <div className="card-body">
             <form>
                <h6 className="heading-small text-muted mb-4">User information</h6>
                <div className="pl-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label" name="input-username">Username</label>
                        <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="Username" value={user.firstName}/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label" name="input-email">Email address</label>
                        <input id="input-email" className="form-control form-control-alternative" placeholder={user.email} value={user.email}/>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label" name="input-first-name">First name</label>
                        <input type="text" id="input-first-name" className="form-control form-control-alternative" placeholder="First name" value={user.firstName}/>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group focused">
                        <label className="form-control-label" name="input-last-name">Last name</label>
                        <input type="text" id="input-last-name" className="form-control form-control-alternative" placeholder="Last name" value={user.lastName}/>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="my-4"/>
                {/* <!-- Address --> */}
                <h6 className="heading-small text-muted mb-4">Contact information</h6>
                <div className="pl-lg-4">
                  {/* <div className="row">
                    <div className="col-md-12">
                      <div className="form-group focused">
                        <label className="form-control-label" name="input-address">Address</label>
                        <input id="input-address" className="form-control form-control-alternative" placeholder="Home Address" value="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09" type="text"/>
                      </div>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group focused">
                        <label className="form-control-label" name="input-city">Phone</label>
                        <input type="text" id="input-city" className="form-control form-control-alternative" placeholder="" value={user.number}/>
                      </div>
                    </div>
                    {/* <div className="col-lg-4">
                      <div className="form-group focused">
                        <label className="form-control-label" name="input-country">Country</label>
                        <input type="text" id="input-country" className="form-control form-control-alternative" placeholder="Country" value="United States"/>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-control-label" name="input-country">Postal code</label>
                        <input type="number" id="input-postal-code" className="form-control form-control-alternative" placeholder="Postal code"/>
                      </div>
                    </div> */}
                  </div>
                </div>
                <hr className="my-4"/>
              
                <h6 className="heading-small text-muted mb-4">About me</h6>
                <div className="pl-lg-4">
                  <div className="form-group focused">
                    <label>About Me</label>
                    <textarea rows="4" className="form-control form-control-alternative" placeholder="A few words about you ...">I am a IELTS student.</textarea>
                  </div>
                </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )}
  </>
  );
};

export default UserProfile;
