import React from 'react';
import { useAppSelector } from '../../../chatApp/hooks';
import Header from './Header';
import Navbar from './Navbar';
import Modals from './Modal';
import Notification from './Notification';
import Modal from 'react-modal';

const Layout = ({ children }) => {
  const notificationActive = useAppSelector(state => state.notification.isActive);
  const modalData = useAppSelector(state => state.modal);
  //const [modalIsOpen, setModalIsOpen] = React.useState(false);
 // const closeModal = () => setModalIsOpen(false);
  return (
    <>
      <Navbar />
      <Header />
      {notificationActive && <Notification/>}
      <div className="section is-flex-grow-1 is-flex is-flex-direction-column">{children}</div>
      <Modal
        isOpen={modalData.isActive}
       // onRequestClose={closeModal}
        contentLabel="Form Modal"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            padding: '0',
            maxWidth: '450px',
            width: '100%',
            overflow: 'visible', 
          },
        }}
      >
 <Modals />
      </Modal>
     
    </>
  );
};

export default Layout;