import React from 'react';
import { useAppSelector } from '../../../../chatApp/hooks';
import Modal from 'react-modal';
import Loader from "../../../Loader/loader";
import { alignProperty } from '@mui/material/styles/cssUtils';
const Notification: React.FC = () => {
  const notificationData = useAppSelector(state => state.notification);
  return (
    <Modal
    isOpen={true}
    // onRequestClose={closeModal}
     contentLabel="Form Modal"
     ariaHideApp={false}
     style={{
       overlay: {
         backgroundColor: 'rgba(0, 0, 0, 0.5)',
       },
       content: {
         top: '50%',
         left: '50%',
         right: 'auto',
         bottom: 'auto',
         marginRight: '-50%',
         transform: 'translate(-50%, -50%)',
         border: 'none',
         padding: '0',
         maxWidth: '450px',
         width: '100%',
         overflow: 'visible', 
       },
     }}
    >
    <div className="checkmodeel" style={{ textAlign: "center" }}>
     <h1> {notificationData.notificationContent} </h1>
      {notificationData.isLoading && <Loader/>}
    </div>
    </Modal>

  );
};

export default Notification;