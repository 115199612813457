import React, { useEffect, useRef, useState } from "react"
import "./courses.css"
import { coursesCard } from "../../dummydata"
import { useNavigate } from "react-router-dom";
import { GET_USER, RAZORPAY, RAZORPAY_VERIFY, REQUEST_TYPE } from "../../api/apiConfig";
import apiCall from "../../api/Requesthandler";
import Cookies from 'js-cookie';

const CoursesCard = ({ course , isDataLoading, handleUsernameSubmit}) => {
  const componentRef = useRef(null)
  const [courses, setCourses] = useState(course);
  const [hasError, setIshasError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigation = useNavigate();
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  
  useEffect(() => {
    if (componentRef.current && !isDataLoading) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []); 


  async function showRazorpay(t) {
    const encodedData = btoa(JSON.stringify(t._id));
    console.log(t._id, 'ttttt',);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const datas = {
      coursename: t.title,
      amount: t.price,
      description: t.description,
      image: t.image,
      userId: Cookies.get('userId'),
    }
    // const data = await fetch("http://localhost:1337/razorpay", {
    //   method: "POST",
    //   data :{
    //   coursename : t.title,
    //   amount: t.price,
    //   description : t.description,
    //   image: t.image
    //   }
    // }).then((t) => t.json());
    apiCall(RAZORPAY.razorpay, REQUEST_TYPE.POST, datas, Cookies.get('access_token')).then((res) => {

      console.log(res, 'res.response.data.data');

      const data = res.response.data.data;



      console.log('data');
      console.log(data);

      const options = {
        key: "rzp_test_aRKcZ7OzzaKSTC",
        currency: data.currency,
        amount: data.amount,
        order_id: data.id,
        name: t.title,
        description: t.description,
        image: t.image,
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          console.log('response');
          console.log(response);

          const paymentData = {
            razorpay_order_id: response?.razorpay_order_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_signature: response?.razorpay_signature,
            userId: Cookies.get('userId'),
            coursename: t.title,
            course_id: t._id,
            amount: t.price,
            userId: Cookies.get('userId'),

          }
          apiCall(RAZORPAY.verify, REQUEST_TYPE.POST, paymentData, Cookies.get('access_token')).then((res) => {

            console.log(res?.response.data.message, "Payment Successfull");
            if (res?.response?.data?.message == "Payment Successfull") {


              navigation(`/CourseHome/${encodedData}`);
            }else{
              alert("Transaction unsuccessful");
            }
       
          })

        },
        prefill: {
          name: "Rajat",
          email: "rajat@rajat.com",
          phone_number: "9899999999",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    })
  }

  const handleClick = async (t) => {

    console.log('Button clicked!', t);
    const encodedData = btoa(JSON.stringify(t._id));
    // Your custom logic goes here


   
    const userId = Cookies.get('userId');

    if (userId !== undefined) {
        apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
  
          console.log(res.hasError, 'reseee');
          if (t?.enroll && !res.hasError) {
            navigation(`/CourseHome/${encodedData}`);
          }else if( !t?.enroll && !res.hasError){
            showRazorpay(t)
          }else{
            navigation(`/login`);
          }
        })
  
      }else{
        navigation(`/login`);
      }





  }
  
 
  return (
    <>
      <section className='coursesCard' ref={componentRef}>
        <div className='categories-grid'>
          {course?.map((val) => (
            <div className='items' onClick={() =>  navigation(`/CourseDetails/${val.name}`, { state: { course: val } })}>
               
                  <div className='img'>
                    <img src= {val.image} alt='' /> 
                   
                  </div>
                
              <div className='content'>
                <div className='text'>
                  <h1>{val.title}</h1>
               
                </div>
              
             
              {!val.enroll ?(
              <div className='prices'>
                <h3>
                  Rs. {val.price} All Course
                </h3>
              </div>)
              :( 
                <></>
              )
             
}
         <div>
              <button className='outline-btn'   onClick={(e) => {
          e.stopPropagation(); // This will prevent the div's onClick from firing
          handleClick(val);
        }}>   {val.enroll ? 'GO To The Course' : 'ENROLL NOW !'}</button>
             
              {/* {val.enroll &&<button className='outline-btn' onClick={() => handleUsernameSubmit(val)}>   {'CHAt NOW !'}</button>} */}
              </div>
         
              </div>
              </div>
       
          ))}
        </div>
      </section>
    </>
  )
}

export default CoursesCard



