/**
 * Modal that requires user action to accept/decline
 */
import React from 'react';
import { useAppSelector, useAppDispatch } from '../../../../chatApp/hooks';
import { User } from '../../../../util/types';
import { resetModal } from '../../../../chatApp/features/modalSlice';
import { sendInvite, sendInviteAccepted, sendDeclineInvite } from '../../../../services/socket/publishers';
import { setNotificationSendInvite } from '../../../../util/middleware/appActions/notification';
import './model.css'
const ActionModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const modalData = useAppSelector(state => state.modal);
  const activeUsers = useAppSelector(state => state.activeUsers.users);
  const peerUsername = modalData.peerId ? activeUsers.find((user: User) => modalData.peerId === user.socketId)!.username : '';

  const handleDeclineandCloseModal = () => {
    if (modalData.modalName === 'private chat request') {
      sendDeclineInvite(modalData.peerId!);
    }

    dispatch(resetModal());
  };

  const handleAcceptandCloseModal = () => {
    if (modalData.modalName === 'send chat invite') {
      sendInvite(modalData.peerId!);
      setNotificationSendInvite(peerUsername);
    }

    if (modalData.modalName === 'private chat request') {
      sendInviteAccepted(modalData.peerId!);
    }

    dispatch(resetModal());
  };

  return (
    <div className="checkmodeel">
          <h1>{modalData.modalContent}</h1>
          <div className="is-flex is-flex-direction-row is-justify-content-space-around">
            <button onClick={handleAcceptandCloseModal} className="is-link">{modalData.confirmBtnText}</button>
            <button onClick={handleDeclineandCloseModal} className="is-danger">{modalData.declineBtnText}</button>
          </div>
    </div>
  );
};

export default ActionModal;