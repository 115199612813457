
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';
import { FaBookReader } from "react-icons/fa";
import { FaDiagnoses } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import logo from "../../assets/AI.png";
import { useNavigate } from "react-router-dom";
import './session.css'

const Container = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
    height:auto;
  }
`;

const LeftPanel = styled.div`
  width: 50%;
  background-color: #1eb2a6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;


const RightPanel = styled.div`
  width: 50%;
  background-color: #ffffff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;
const LeftPanel2 = styled.div`
  width: 60%;
  background-color: #ffffff;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
   @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
 
`;
const RightPanel2 = styled.div`
  width: 40%;
  background-color: #ffffff;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
    @media (max-width: 768px) {
    display: none;
  }
`;

const BackIcon = styled(FaArrowLeft)`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #ffff;
  @media (max-width: 768px) {
    position: static;
    margin-bottom: 20px;
  }
     &:hover {
    color:#333 ;
}
`;

const Heading = styled.h1`
  font-size: 36px;
  color: #ffff;
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const SubmitButton = styled.button`
    box-shadow: none;
    border: 2px solid #1eb2a6;
    width: 100%;
    transition: 0.5s;
    margin-block: 10px;
    &:hover {
    background-color: #1eb2a6;
    color: #fff;
}
`;



const FormLabel = styled.label`
  display: flex;
      justify-content: space-between;
    border: 2px solid gray;
  align-items: center;
  margin-block: 10px;
  padding: 0 20px;
  cursor: pointer;
  border: 2px solid rgb(206, 212, 218);
    width: 100%;
    transition: 0.5s;
`;

const RadioButton = styled.input`
  display: none;

  & + span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: 2px solid #ced4da;
    border-radius: 50%;
    position: relative;
  }

  &:checked + span::after {
    content: '';
    width: 12px;
    height: 12px;
    background-color: #1eb2a6;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Checkbox = styled.input`
  display: none;

  & + span {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    border: 2px solid #ced4da;
    border-radius: 4px;
    position: relative;
  }

  &:checked + span::after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="%231eb2a6" d="M13.485 1.5a.8.8 0 011.13 1.13l-8 8a.8.8 0 01-1.13 0l-4-4a.8.8 0 011.13-1.13l3.485 3.485 7.385-7.385z"/></svg>');
    width: 18px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const SideForm = () => {
  const navigation = useNavigate();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    language: 'English',
    goals: '',
    topics: [],
    level: ''
  });



  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === 'radio') {
      setFormData((prev) => ({ ...prev, [name]: value }));
    } else if (type === 'checkbox') {
      setFormData((prev) => {
        const newValue = checked
          ? [...prev[name], value]
          : prev[name].filter((item) => item !== value);
        return { ...prev, [name]: newValue };
      });
    } else if (type === 'select-multiple') {
      const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
      setFormData((prev) => ({ ...prev, [name]: selectedOptions }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
  
        <Container>

{step === 0 && (
   <>
  <LeftPanel2 className='leftpanel2'>
  {/* <img src={logo} alt='' /> */}
            <div style={{ width: '100%' }}>
              <h1>Find the right tutor for you.</h1>
              <br></br>
              <p>Tell us how you'd like to learn to get a personalized choice of tutors</p>
              <br></br>
              <form style={{  textAlign: 'left' }}>
                <label htmlFor="language">What do you want to learn?</label>
                <div className='select-language'>
                <select
                  id="language"
                  name="language"
                  style={{ display: 'block', margin: '10px 0', padding: '10px', fontSize: '16px' }}
                  onChange={handleChange}
                  value={formData.language}
                >
                 
                  <option value="English">English</option>
               
                </select>
                <SubmitButton className='su-btn' type="button" onClick={handleNext}>
                  Get started
                </SubmitButton>
                </div>
              </form>
              <a href="" style={{ display: 'block', marginTop: '20px', }}>
                Choose by myself from 23,519 tutors
              </a>
            </div>
            </LeftPanel2>
            <RightPanel2 className='RightPanel'>
            <img src="./images/blog/b3.webp" alt='' />
            </RightPanel2>
           </>
          )}
          {step > 0 && (
            <>
          <LeftPanel>
          <BackIcon onClick={handleBack}/>
            {step === 1 && <Heading>What's your goals?</Heading>}
            {step === 2 && <Heading>Which topics do you want to focus on?</Heading>}
            {step === 3 && <Heading>What's your English level?</Heading>}
          </LeftPanel>
          <RightPanel>
            {step > 0 && <BackIcon onClick={handleBack} className="mobile-only" />}
            {step === 1 && (
              <>
                <Heading className="mobile-only">What's your goals?</Heading>
                <form className='sessionForm'>
                  <FormLabel className={formData.goals === 'Career and business' ? 'checked' : ''}>
                    
                  
                    <h2><span> <FaGlobe /></span>   Career and business</h2>
                    <RadioButton
                      type="radio"
                      name="goals"
                      value="Career and business"
                      onChange={handleChange}
                      checked={formData.goals === 'Career and business'}
                    />
                    <span></span>
                  </FormLabel>
                  <FormLabel className={formData.goals === 'Lessons for kids' ? 'checked' : ''}>
                    <h2><span><FaDiagnoses /></span> Lessons for kids</h2>
                    <RadioButton
                      type="radio"
                      name="goals"
                      value="Lessons for kids"
                      onChange={handleChange}
                      checked={formData.goals === 'Lessons for kids'}
                    />
                    <span></span>
                  </FormLabel>
                  <FormLabel  className={formData.goals === 'Exams and course work' ? 'checked' : ''}>
                  <h2><span><FaBookReader /></span> Exams and course work</h2>  
                    <RadioButton
                      type="radio"
                      name="goals"
                      value="Exams and course work"
                      onChange={handleChange}
                      checked={formData.goals === 'Exams and course work'}
                    />
                    <span></span>
                  </FormLabel>
                  <SubmitButton type="button" onClick={handleNext}>
                    Next
                  </SubmitButton>
                </form>
              </>
            )}
            {step === 2 && (
              <>
                <Heading className="mobile-only">Which topics do you want to focus on?</Heading>
                <form>
                  <FormLabel className={formData.topics.includes('Conversational English') ? 'checked' : ''}>
                    <h2>Conversational English</h2>
                    <Checkbox
                      type="checkbox"
                      name="topics"
                      value="Conversational English"
                      onChange={handleChange}
                      checked={formData.topics.includes('Conversational English')}
                    />
                    <span></span>
                  </FormLabel>
                  <FormLabel className={formData.topics.includes('English for beginners') ? 'checked' : ''}>
                    <h2>English for beginners</h2>
                    <Checkbox
                      type="checkbox"
                      name="topics"
                      value="English for beginners"
                      onChange={handleChange}
                      checked={formData.topics.includes('English for beginners')}
                    />
                    <span></span>
                  </FormLabel>
                  <FormLabel className={formData.topics.includes('Intensive English') ? 'checked' : ''}>
                    <h2>Intensive English</h2>
                    <Checkbox
                      type="checkbox"
                      name="topics"
                      value="Intensive English"
                      onChange={handleChange}
                      checked={formData.topics.includes('Intensive English')}
                    />
                    <span></span>
                  </FormLabel>
                  <FormLabel className={formData.topics.includes('Business English') ? 'checked' : ''}>
                    <h2>Business English</h2>
                    <Checkbox
                      type="checkbox"
                      name="topics"
                      value="Business English"
                      onChange={handleChange}
                      checked={formData.topics.includes('Business English')}
                    />
                    <span></span>
                  </FormLabel>
                  <SubmitButton type="button" onClick={handleNext}>
                    Next
                  </SubmitButton>
                </form>
              </>
            )}
            {step === 3 && (
              <>
                <Heading className="mobile-only">What's your English level?</Heading>
                <form>
                  <FormLabel className={formData.level === "I'm just starting" ? 'checked' : ''}>
                   <h2>I'm just starting</h2> 
                    <RadioButton
                      type="radio"
                      name="level"
                      value="I'm just starting"
                      onChange={handleChange}
                      checked={formData.level === "I'm just starting"}
                    />
                    <span></span>
                  </FormLabel>
                  <FormLabel className={formData.level === "I know the basics" ? 'checked' : ''}>
                   <h2>I know the basics</h2> 
                    <RadioButton
                      type="radio"
                      name="level"
                      value="I know the basics"
                      onChange={handleChange}
                      checked={formData.level === "I know the basics"}
                    />
                    <span></span>
                  </FormLabel>
                  {/* console.log(formData) */}
                  <SubmitButton type="button" onClick={() =>  navigation('/selectedsessions')}> 
                    Submit
                  </SubmitButton>
                </form>
              </>
            )}
          </RightPanel>
          </>
          )}
        </Container>
   
     
  );
};

export default SideForm;



























// import React, { useState } from 'react';
// import styled from 'styled-components';
// import { FaArrowLeft } from 'react-icons/fa';
// import "./session.css"
// const Container = styled.div`
//   display: flex;
//   height: 100vh;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `;

// const LeftPanel = styled.div`
//   width: 50%;
//   background-color: #f8f9fa;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   @media (max-width: 768px) {
//     display: none;
//   }
// `;

// const RightPanel = styled.div`
//   width: 50%;
//   background-color: #ffffff;
//   padding: 40px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   position: relative;
//   @media (max-width: 768px) {
//     width: 100%;
//     padding: 20px;
//   }
// `;

// const BackIcon = styled(FaArrowLeft)`
//   position: absolute;
//   top: 20px;
//   left: 20px;
//   cursor: pointer;
//   font-size: 24px;
//   color: #333;
//   @media (max-width: 768px) {
//     position: static;
//     margin-bottom: 20px;
//   }
// `;

// const Heading = styled.h1`
//   font-size: 36px;
//   color: #333;
//   @media (max-width: 768px) {
//     font-size: 28px;
//     margin-bottom: 20px;
//   }
// `;

// const InputField = styled.input`
//   margin: 10px 0;
//   padding: 15px;
//   border: 1px solid #ced4da;
//   border-radius: 5px;
//   font-size: 16px;
// `;

// const TextAreaField = styled.textarea`
//   margin: 10px 0;
//   padding: 15px;
//   border: 1px solid #ced4da;
//   border-radius: 5px;
//   font-size: 16px;
//   resize: none;
// `;

// const SubmitButton = styled.button`
//   background-color: #28a745;
//   color: white;
//   border: none;
//   padding: 15px 20px;
//   cursor: pointer;
//   border-radius: 5px;
//   font-size: 16px;
//   align-self: flex-start;
//   margin-top: 20px;
// `;

// const SideForm = () => {
//   const [isOpen, setIsOpen] = useState(true);

//   const toggleForm = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <>
//       {isOpen && (
//         <Container>
//           <LeftPanel>
//             <BackIcon onClick={toggleForm} />
//             <Heading>Get Started</Heading>
//           </LeftPanel>
//           <RightPanel>
//             <BackIcon onClick={toggleForm} className="mobile-only" />
//             <Heading className="mobile-only">Get Started</Heading>
//             <form className='sessionFrom'>
//               <InputField type="text" placeholder="Your Name" />
//               <InputField type="email" placeholder="Your Email" />
//               <TextAreaField rows="5" placeholder="Your Message"></TextAreaField>
//               <SubmitButton>Submit</SubmitButton>
//             </form>
//           </RightPanel>
//         </Container>
//       )}
//       {!isOpen && (
//         <button
//           onClick={toggleForm}
//           style={{
//             padding: '10px 20px',
//             borderRadius: '5px',
//             backgroundColor: '#0061f2',
//             color: 'white',
//             border: 'none',
//             cursor: 'pointer',
//             position: 'fixed',
//             top: '20px',
//             right: '20px',
//           }}
//         >
//           Open Form
//         </button>
//       )}
//     </>
//   );
// };

// export default SideForm;