import React, { useEffect, useState } from "react"
import "./Coursedays.css"
import { online3 } from "../../dummydata"
import Heading from "../common/heading/Heading"
import PriceCard from "../pricing/PriceCard"
import { useNavigate, useParams } from "react-router-dom";
import Header from "../common/header/Header"
import Back from "../common/back/Back"
import apiCall from "../../api/Requesthandler"
import { GET_CONTENT_DETAIL, GET_COURSE_ENROLLMENT, GET_LEVEL_DETAIL, REQUEST_TYPE } from "../../api/apiConfig"
import Cookies from 'js-cookie';
import Loader from "../Loader/loader"
import ScrollToTopOnRouteChange from "../scroll/ScrollToTopOnRouteChange"
const OnlineCourses = ( ) => {
    const [daystData, setdaystData] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(false);
  const navigation = useNavigate(); 
  const { levelId } = useParams();
  


      useEffect(() => {

        setIsDataLoading(true)
    
        const decodedlevelId = atob(levelId);
    
    
        const userId = Cookies.get('userId');
        if (userId !== undefined) {
          apiCall(GET_LEVEL_DETAIL(JSON.parse(decodedlevelId)), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
    
            console.log(res.response.data.data.day, 'GET_LEVEL_DETAIL');
            const idToCheck = res.response.data.data.courseName;
            console.log(idToCheck, '1');
            const days = res.response.data.data.day;
    
    
            apiCall(GET_COURSE_ENROLLMENT(Cookies.get('userId')), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
              console.log(res.response.data.data, 'enronn');
              const enrolledCourses = res.response.data.data;
    
              // Step 3: Check if idToCheck exists in the enrolledCourses array
              const exists = enrolledCourses.some(course => course.name === idToCheck);
    
              console.log(`ID exists in enrolled courses: ${exists}`);
              if (exists) {
    
                setdaystData(days)
                setIsDataLoading(false)
              } else {
                navigation(`/courses`)
              }
    
            })
    
          })
    
        }
    
      }, []);
  const handleSubmit= (val)=>{
    console.log(val._id);
    const encodedData = btoa(JSON.stringify(val._id));
    navigation(`/leveldays/${encodedData}/${val.courseName}`) 


  }
  return (
    <>
     <ScrollToTopOnRouteChange />
      {/* <Header />
      <Back title={title} /> */}
      {isDataLoading ? <Loader /> :
      <section className='online'>
        <div className='container'>
          <Heading subtitle='LESSONS'/>
          {/* <div className='content grid3'>
            {online.map((val) => (
              <div className='box'>
                <div className='img'>
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt='' className='show' />
                </div>
                <h1>{val.courseName}</h1>
                <span>{val.course}</span>
              </div>
            ))}
          </div> */}


             <div className='grid'>
            {daystData.map((val) => (
              <div className='box' onClick={()=>{
                handleSubmit(val)
              }}>
                {/* <div className='img'>
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt='' className='show' />
                </div> */}
                <h1>{val.title}</h1>
                {/* <span>{val.course}</span> */}
              </div>
            ))}
          </div>

          
        </div>
      </section>
}
    </>
  )
}

export default OnlineCourses
