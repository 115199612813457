import "./App.css"
import Header from "./components/common/header/Header"
import { BrowserRouter as Router, Route, Routes, createBrowserRouter, RouterProvider, useLocation } from "react-router-dom"
import About from "./components/about/About"
import CourseHome from "./components/allcourses/CourseHome"
import Course from "./components/courses/CourseHome"
import CourseChat from "./components/coursechat/CourseHomeChat"
import CourseLevel from "./components/courselevel/Courselevel"
import Leveldays from "./components/leveldays/leveldays"
import CourseDays from "./components/days/Coursedays"
import SignUpHome from "./components/signuplogin/signuphome"
import Team from "./components/team/Team"
import Pricing from "./components/pricing/Pricing"
import SessionFrom from "./components/Sessions/FormContainer"
import Blog from "./components/blog/Blog"
import Contact from "./components/contact/Contact"
import Footer from "./components/common/footer/Footer"
import Home from "./components/home/Home"
import { useEffect, useState } from "react"
import apiCall from "./api/Requesthandler"
import { GET_USER, REQUEST_TYPE } from "./api/apiConfig"
import UserProfile from './components/Userprofile/userprofile';
import PrivateRoutes from './components/utils/PrivateRoutes';
import AdminRoutes from './components/utils/AdminRoutes';
import AdminDashboard from './components/admin/AdminDashboard/AdminDashboard';
import PrivateRoom from './pages/PrivateRoom';
//import './styles/app.scss';

import Cookies from 'js-cookie';
import NotFound from "./components/NotFound/NotFound"
import CourseDetails from "./components/coursedetails/CourseDetails"
function App() {
  const [courseHome, setCourseHome] = useState('');
  const [user, setUser] = useState(null)
  const user1 = {
    name: 'John Doe',
    avatar: 'https://via.placeholder.com/150',
    bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis eros id elit cursus posuere.',
    courses: [
      { id: 1, title: 'Introduction to React' },
      { id: 2, title: 'Advanced CSS Techniques' },
      { id: 3, title: 'JavaScript Fundamentals' }
    ]
  };
  const handleCourseSet = (data) => {
    console.log(data);
    setCourseHome(data);



  }


  // const router = createBrowserRouter([
  //   {
  //     path: "/admin/",
  //     element: <AdminDashboard />,
  //     children: [
  //       {
  //         path: '/',
  //         element: <Home />,
  //       },
  //       {
  //         path: '/users',
  //         element: <UserList />,
  //       },

  //     ]
  //   },

  // ]);


  useEffect(() => {
    const userId = Cookies.get('userId');
    if (userId !== undefined) {
      apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {

        console.log(res?.response?.data?.data?.firstName);
        setUser(res?.response?.data?.data)
      })


    }
  }, []);





  return (
    <>




      <Router>
        <Header user={user} />
        <Routes>
          {/* <Switch> */}
          <Route exact path='/'
            element={
              <Home Header={<Header />} />
            }
          />



          {/* <Route exact path='/courses'
            element={
              <Course course={handleCourseSet} />
            }></Route> */}


          <Route exact path='/signUp'
            element={
              <SignUpHome value='signup' />
            }></Route>

<Route exact path='/CourseDetails/:coursename'
            element={
              <CourseDetails/>
            }></Route>

          <Route exact path='/activateUser/:encodedData'
            element={
              <SignUpHome value='activateUser' />
            }></Route>



          <Route exact path='/login'
            element={
              <SignUpHome value='login' />
            }></Route>

          <Route exact path='/recover_password'
            element={
              <SignUpHome value='recover_password' />
            }></Route>

          <Route exact path='/about'
            element={
              <About />
            }></Route>
          {/* <Route exact path='/experts'
            element={
              <Team />
            }></Route> */}
          {/* <Route exact path='/sessions'
            element={
              <Blog />
            }></Route> */}

          <Route exact path='/sessions'
            element={
              <SessionFrom />
            }></Route>

          <Route exact path='/selectedsessions'
            element={
              <Blog />
            }></Route>

          <Route exact path='/joinChat'
            element={
              <CourseChat />
            }></Route>


          <Route exact path='/p-room/:id'
            element={
              <PrivateRoom />
            }></Route>

          <Route exact path='/FAQs'
            element={
              <Pricing />
            }></Route>


          <Route exact path='/contact'
            element={
              <Contact />
            }></Route>
          <Route exact path='/courses/:categoryid'
            element={
              <Course course={handleCourseSet} />
            }></Route>



          <Route element={<PrivateRoutes />}>

            <Route exact path='/userProfile'
              element={
                <UserProfile user={user} />
              }></Route>

            <Route exact path='/CourseHome/:courseid'
              element={
                <CourseHome />
              }></Route>
            <Route exact path='/lessons/:contentId'
              element={
                <CourseLevel />
              }></Route>

            <Route exact path='/coursedays/:levelId'
              element={
                <CourseDays />
              }></Route>

            <Route exact path='/leveldays/:levelId/:courseName'
              element={
                <Leveldays />
              }></Route>


          </Route>


          {/* <Route element={<AdminRoutes />}>

            <Route exact path='/admin/*' element={<AdminDashboard />} />
          
          </Route> */}

          <Route path="*"
            element={
              <NotFound />
            }
          />
        </Routes>


        <Footer />
      </Router>


      {/* <Footer /> */}



      {/* <>
        <Footer/>
    </> */}
      {/* <RouterProvider router={router} /> */}
    </>


  )
}

export default App
