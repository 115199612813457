import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Head from "./Head"
import "./header.css"
import Cookies from 'js-cookie';
import { useAppSelector } from "../../../chatApp/hooks";
import { resetAppUser } from '../../../util/middleware/appActions/user';
import { sendRemoveUser } from '../../../services/socket/publishers';
import LoginIcon from '@mui/icons-material/Login';


const Header = ({user}) => {
  console.log(user);
  const [click, setClick] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();

  const username = useAppSelector(state => state.user.username);
  // Check if the current path is '/leveldays'
  const hideHeader = location.pathname.includes('/admin') || location.pathname.includes('/leveldays')  || location.pathname.includes('/userProfile')  || location.pathname.includes('/login') || location.pathname.includes('/signUp') || location.pathname.includes('/activateUser')  || location.pathname.includes('/recover_password') || location.pathname.includes('/Sessions') || location.pathname.includes('/p-room/');
  const isCourseHome = location.pathname.includes('/CourseHome') || location.pathname.includes('/lessons') || location.pathname.includes('/coursedays') || location.pathname.includes('/*')  || location.pathname.includes('/CourseDetails/');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlelogout = ()=>{
    console.log('hi');
    const cookies = Cookies.get();

    // Iterate through each cookie and remove it
    for (const cookie in cookies) {
      Cookies.remove(cookie);
  }

  if (Object.keys(Cookies.get()).length === 0) {
   navigation("/")
   window.location.reload();
  } else {
    console.error('Failed to remove some cookies!');
  }
  }

  //const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/p-room') && !location.pathname.includes('/joinChat') ) {
      console.log('why entereeeeeeeeeeeeeeeedddddddddd here');
      
      resetAppUser();
      sendRemoveUser();
    }

  }, [location]);
  return (
    <>
     {!hideHeader &&  !username &&(
<>
<Head />
      <header className={isCourseHome ? 'coursehome-header' : 'navheader'}>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <li>
              <Link to='/'>Home</Link>
            </li>
            <li>
              <Link to='/courses/all'>All Courses</Link>
            </li>
            <li>
              <Link to='/about'>About</Link>
            </li>
            {/* <li>
              <Link to='/experts'>Experts</Link>
            </li> */}
            {/* <li>
              <Link to='/FAQs'>FAQs</Link>
            </li> */}
            {/* <li>
              <Link to='/journal'>Journal</Link>
            </li> */}
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
            {/* <li>
              <Link to='/Sessions'>Sessions</Link>
            </li> */}
            {/* <li>
              <Link to='/joinChat'>Chats</Link>
            </li> */}
          </ul>
          <div style={{padding:"10px 40px"}}> 
          {!user&&
        <Link  to='/login'> <button className='outline-btn' style={{display:"flex", alignItems:"center",gap:"2px",marginRight:'10px'}}> <LoginIcon/> Log In</button></Link>
          }
             {user&&
             <div className="dropdown"    onMouseEnter={toggleDropdown}  onMouseLeave={toggleDropdown}>
             <div className="btn2" >
             {user.firstName.charAt(0).toUpperCase()}
             </div>
             {isOpen && (
               <div className="dropdown-content" >
                 <a href="#"> <Link to='/userProfile'>User Profile</Link></a>
                 <a href="#"><Link to='/userProfile'>Settings</Link></a>
                 <a onClick={handlelogout} href="#"><Link to=''>Logout</Link></a>
               </div>
             )}
           
           </div>
          }
           
            
            {/* <div className='button'>Sign Up</div> */}
          </div>

          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
          </button>
        </nav>
      
      </header>
      {/* {isOpen && (
               <div className="dropdown-content" onMouseLeave={toggleDropdown}>
                 <a href="#"> <Link to='/userProfile'>User Profile</Link></a>
                 <a href="#"><Link to='/userProfile'>Settings</Link></a>
                 <a onClick={handlelogout} href="#"><Link to=''>Logout</Link></a>
               </div>
             )} */}
</>
     )}
   
    </>
  )
}

export default Header
