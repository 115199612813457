import React from "react"

import Signupcard from "./signupcard"
import Logincard from "./logincard"
import Activatecard from './activateCard'
import Recovercard from './recover_password'


import "./signuphome.css"
import ScrollToTopOnRouteChange from "../scroll/ScrollToTopOnRouteChange"

const signuphome = ({value}) => {
  console.log(value);
  return (
    <>
      <ScrollToTopOnRouteChange />
{/* <Header/> */}
{value==='signup' &&
      <Signupcard  />
}
{value==='login' &&
      <Logincard />
}
{value ==="activateUser"&&
<Activatecard/>

}
{value ==="recover_password"&&
<Recovercard/>

}





      {/* <Signupcard />
      <OnlineCourses /> */}
    </>
  )
}

export default signuphome
