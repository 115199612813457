import React from "react"
import logo from "../../../assets/AI.png"
const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB logos'>
          <div className='logo'>
            {/* <h1>FLUENCIFY</h1>
            <span>ONLINE EDUCATION & LEARNING</span> */}
            <img src={logo} alt="" />
          </div>

          <div className='social'>
            <a href="https://www.instagram.com/fluencify.ai/" target='_blank'><i className='fab fa-instagram icon'></i></a>
            <a href="https://www.linkedin.com/in/fluencify-ai-1007372ba/" target='_blank'><i className='fab fa-linkedin-in icon'></i></a>
            <a href="#" target='_blank'><i className='fab fa-youtube icon'></i></a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
