import React, { useEffect, useRef, useState } from "react"
import { blog } from "../../dummydata"
import { GET_USER, RAZORPAY, RAZORPAY_VERIFY, REQUEST_TYPE } from "../../api/apiConfig";
import apiCall from "../../api/Requesthandler";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import DatePickerStyles from './DatePickerStyles';
import 'react-datepicker/dist/react-datepicker.css';



const FormWrapper = styled.div`
  background: #ffffff;
  padding: 40px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: 450px;
  max-width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
  text-align: center;
  font-family: 'Arial', sans-serif;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Select = styled.select`
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
 
  font-size: 16px;
  &:focus {
    border-color: #1eb2a6;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
 
  font-size: 16px;
  width: 100%;
  &:focus {
    border-color: #1eb2a6;
    outline: none;
    box-shadow: #1eb2a6;
  }
`;
const Terms = styled.p`
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 12px;
  background-color: #1eb2a6;
  color: white;
  border: none;
 
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #ffff;
    color:#1eb2a6;
    border: 2px solid #1eb2a6;
  }
`;
const Label = styled.label`
  font-size: 16px;
  color: #555;
  margin-bottom: 8px;
  font-family: 'Arial', sans-serif;
`;

const validationSchema = Yup.object({
  time: Yup.string().required('Time is required'),
  date: Yup.date()
    .typeError('Invalid date format')
    .required('Date is required')
    .nullable(),
  sessionDuration: Yup.string().required('Session duration is required'),
});

const timeOptions = [];
for (let hour = 9; hour <= 17; hour++) {
  timeOptions.push(`${hour}:00`);
  timeOptions.push(`${hour}:30`);
}
timeOptions.push('18:00');

const durationOptions = ['Half Hour(30 Minutes)', 'An Hour(60 Minutes)'];

const BlogCard = () => {
  const navigation = useNavigate();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selctedSession, setSelctedSession]=React.useState(null);
  const [useremail, setEmail] = React.useState('')
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  function addDaysExcludingSundays(startDate, daysToAdd) {
    let date = new Date(startDate);
    let daysAdded = 0;

    while (daysAdded < daysToAdd) {
        date.setDate(date.getDate() + 1);
        if (date.getDay() !== 0) { // Exclude Sundays
            daysAdded++;
        }
    }

    return date;
}
  async function showRazorpay(t) {
      // {"time":"16:30","date":"2024-07-24T18:30:00.000Z","sessionDuration":"Half Hour"}
console.log(addDaysExcludingSundays(new Date(t.date), 26).toISOString());
    const res = await loadScript( 
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const datas = {
      coursename: selctedSession,
      amount: t?.sessionDuration=='Half Hour'?2000:3000,
      description: selctedSession,
      //image: t.image,
      userId: Cookies.get('userId'),
    }
    // const data = await fetch("http://localhost:1337/razorpay", {
    //   method: "POST",
    //   data :{
    //   coursename : t.title,
    //   amount: t.price,
    //   description : t.description,
    //   image: t.image
    //   }
    // }).then((t) => t.json());
    apiCall(RAZORPAY.razorpay, REQUEST_TYPE.POST, datas, Cookies.get('access_token')).then((res) => {

      console.log(res, 'res.response.data.data');

      const data = res.response.data.data;



      console.log('data');
      console.log(data, 'rajorpaydata');

      const options = {
        key: "rzp_test_aRKcZ7OzzaKSTC",
        currency: data.currency,
        amount: data.amount,
        order_id: data.id,
        name: selctedSession,
        description:selctedSession,
        //image: t.image,
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          console.log('response');
          console.log(response);

          const paymentData = {
            razorpay_order_id: response?.razorpay_order_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_signature: response?.razorpay_signature,
            name: selctedSession,
            amount: t?.sessionDuration=='Half Hour'?2000:3000,
            userId: Cookies.get('userId'),
            minute:t.sessionDuration,
            time:t.time,
            startDate:new Date(t.date).toISOString(),
            endDate:addDaysExcludingSundays(new Date(t.date), 26).toISOString(),
            userEmail: useremail


          }
          console.log(paymentData);
          apiCall(RAZORPAY.verify, REQUEST_TYPE.POST, paymentData, Cookies.get('access_token')).then((res) => {
            if (res?.response?.data?.message == "Payment Successfull") {


              navigation(`/userSessions`);
            }else{
              alert("Transaction unsuccessful");
            }
       
          })

        },
        prefill: {
          name: "Rajat",
          email: "rajat@rajat.com",
          phone_number: "9899999999",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    })
  }
  const handleClick = async (t) => {

    console.log('Button clicked!', t);
    //const encodedData = btoa(JSON.stringify(t._id));
    // Your custom logic goes here


   
    const userId = Cookies.get('userId');

    if (userId !== undefined) {
        apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
  
          console.log(res.hasError, 'reseee');
          if (!res.hasError){
            console.log('hi');
            showRazorpay(t)
          }else{
            navigation(`/login`);
          }
        })
  
      }else{
        navigation(`/login`);
      }






  }



  const openModal = (val) => {
    const userId = Cookies.get('userId');

    if (userId !== undefined) {
        apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
  
          console.log(res.hasError, 'reseee');
          if (!res.hasError){
            setSelctedSession(val.title)
            setEmail(res.response.data.data.email)
            setModalIsOpen(true);
          }else{
            navigation(`/login`);
          }
        })
  
      }else{
        navigation(`/login`);
      }
  

  }
    
   
  const closeModal = () => setModalIsOpen(false);

  const formik = useFormik({
    initialValues: {
      time: '',
      date: '',
    },
    validationSchema,
    onSubmit: values => {
      // {"time":"16:30","date":"2024-07-24T18:30:00.000Z","sessionDuration":"Half Hour"}
      closeModal();
      showRazorpay(values)
    },
  });

  const handleDateChange = date => {
    // Update local state
    setSelectedDate(date);
    
    // Update Formik's field value
    formik.setFieldValue('date', date);
  
    // Trigger validation manually
    if (date === null) {
      // If the date is null, set an error explicitly
      formik.setFieldError('date', 'Date is required');
    } else {
      // Otherwise, validate the date field
      formik.validateField('date');
    }
  };
  return (
    <>
<div className="sessionCard">
<div className=' categories-grid'>
      {blog.map((val) => (
        <div className='items '>
          <div className='img'>
            <img src={val.cover} alt='' />
          </div>
          <div className="content">
          <div className='text'>
            <div className='admin flexSB'>
              <span>
                <i className='fa fa-user'></i>
                <label htmlFor=''>{val.type}</label>
              </span>
              {/* <span>
                <i className='fa fa-calendar-alt'></i>
                <label htmlFor=''>{val.date}</label>
              </span> */}
              <span>
                <i className='fa fa-comments'></i>
                <label htmlFor=''>{val.com}</label>
              </span>
            </div>
            <br />
            <h1>{val.title}</h1>
            {/* <p>{val.desc}</p> */}
          </div>
          <div class="prices">
            <h3>Half-hour sessions: ₹2000</h3>
            <h3>One-hour sessions: ₹3000</h3>
          </div>
          <div className="outline-div">
          <button className='outline-btn' onClick={() => openModal(val)}>Book Sessions</button>
          </div>
          </div>
        </div>
      ))}
      </div>
      </div>
          <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Form Modal"
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            padding: '0',
            maxWidth: '450px',
            width: '100%',
            overflow: 'visible', 
          },
        }}
      >
        <DatePickerStyles />
        <FormWrapper>
        <div className='blog'>
          <h1>{selctedSession}</h1>
          <p>Each session lasts for 26 days, excluding Sundays. The timing of each session can be either half an hour or one hour, based on the user's preference.</p>
          </div>
          <br></br>
          <br></br>
          <StyledForm onSubmit={formik.handleSubmit}>
          <Label htmlFor="sessionDuration">Select Session Duration</Label>
            <Select
              name="sessionDuration"
              id="sessionDuration"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.sessionDuration}
            >
              <option value="" label="Select session duration" />
              {durationOptions.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            {formik.touched.sessionDuration && formik.errors.sessionDuration ? <Error>{formik.errors.sessionDuration}</Error> : null}
            <Label htmlFor="time">Select Time</Label>
            <Select
              name="time"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.time}
            >
              <option value="" label="Select time" />
              {timeOptions.map(time => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </Select>
            {formik.touched.time && formik.errors.time ? <Error>{formik.errors.time}</Error> : null}
            <Label htmlFor="date">Select Start Date</Label>
            <StyledDatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              placeholderText="Select a date"
              dateFormat="MMMM d, yyyy"
              onBlur={formik.handleBlur}
            />
            {formik.touched.date && formik.errors.date ? <Error>{formik.errors.date}</Error> : null}
            <Button type="submit">Book Now</Button>
          </StyledForm>
        </FormWrapper>
      </Modal>
    </>   
  )
}

export default BlogCard
