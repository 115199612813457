import React, { useEffect, useRef, useState } from "react"
import "./courses.css"
import { useNavigate } from "react-router-dom";
// import { GET_USER, RAZORPAY, RAZORPAY_VERIFY, REQUEST_TYPE } from "../../api/apiConfig";
// import apiCall from "../../api/Requesthandler";
import Cookies from 'js-cookie';

const CoursesCard = ({ course , handleUsernameSubmit}) => {
  const componentRef = useRef(null)

  const navigation = useNavigate();
  
  useEffect(() => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []); 


  const handleClick = async (t) => {

    console.log('Button clicked!', t);
    const encodedData = btoa(JSON.stringify(t._id));
    // Your custom logic goes here


   
    const userId = Cookies.get('userId');

    // if (userId !== undefined) {
    //     apiCall(GET_USER(userId), REQUEST_TYPE.GET, {}, Cookies.get('access_token')).then((res) => {
  
    //       console.log(res.hasError, 'reseee');
    //       if (t?.enroll && !res.hasError) {
    //         navigation(`/CourseHome/${encodedData}`);
    //       }else if( !t?.enroll && !res.hasError){
    //         showRazorpay(t)
    //       }else{
    //         navigation(`/login`);
    //       }
    //     })
  
    //   }else{
    //     navigation(`/login`);
    //   }






  }
  return (
    <>
      <section className='coursesCard' ref={componentRef}>
        <div className='categories-grid'>
          {course?.map((val) => (
            <div className='items'>
                  <div className='img'>
                    <img src="https://shiny-puffpuff-667380.netlify.app/assets/images/course/img_06.jpg" alt='' />
                  </div>
              <div className='content'>
                <div className='text'>
                  <h1>{val.title}</h1>
                </div>
                <button className='outline-btn' onClick={() => handleUsernameSubmit(val)}>   {'CHAt NOW !'}</button>
              </div>

             
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default CoursesCard
