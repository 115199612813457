import React, { useEffect, useState } from "react"
import "./Courselevel.css"
import { online3 } from "../../dummydata"
import Heading from "../common/heading/Heading"
import PriceCard from "../pricing/PriceCard"
import { useNavigate, useParams } from "react-router-dom";
import Header from "../common/header/Header"
import Back from "../common/back/Back"
import apiCall from "../../api/Requesthandler"
import { GET_CONTENT_DETAIL, GET_COURSE_ENROLLMENT, REQUEST_TYPE } from "../../api/apiConfig"
import Cookies from 'js-cookie';
import Loader from "../Loader/loader"
import ScrollToTopOnRouteChange from "../scroll/ScrollToTopOnRouteChange"
const OnlineCourses = ({title} ) => {
  const [contentData, setcontentData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const navigation = useNavigate(); 
  const { contentId } = useParams();
  


      useEffect(() => {
        setIsDataLoading(true)

        const decodedContentID = atob(contentId);

     
        const userId = Cookies.get('userId');
        if (userId !== undefined) {
          apiCall(GET_CONTENT_DETAIL(JSON.parse(decodedContentID)), REQUEST_TYPE.GET,{},Cookies.get('access_token')).then((res) => {
    
            console.log(res.response.data.data , 'content');

            const idToCheck = res.response.data.data.courseName;
            console.log(idToCheck , '1');
    const level = res.response.data.data.level;
            // setUser(res.response.data.data)
        


             apiCall(GET_COURSE_ENROLLMENT(Cookies.get('userId')), REQUEST_TYPE.GET,{},Cookies.get('access_token')).then((res) => {
              console.log(res.response.data.data,'enronn');
    const enrolledCourses =res.response.data.data;
    
       // Step 3: Check if idToCheck exists in the enrolledCourses array
       const exists = enrolledCourses.some(course => course.name === idToCheck);
    
       console.log(`ID exists in enrolled courses: ${exists}`);
       if (exists) {
      
        setcontentData(level)
        setIsDataLoading(false)
       }else{
        navigation(`/courses`)
       }
    
            })



          })
    
        }
      }, []);

  const handleSubmit= (_id)=>{
    console.log(_id);
    const encodedData = btoa(JSON.stringify(_id));
    navigation(`/coursedays/${encodedData}`) 


  }
  return (
    <>
     <ScrollToTopOnRouteChange />
      {/* <Header />
      <Back title={title} /> */}
      {isDataLoading ? <Loader /> :
      <section className='online'>
        <div className='container'>
          <Heading subtitle='LESSONS' title={title}/>
          {/* <div className='content grid3'>
            {online.map((val) => (
              <div className='box'>
                <div className='img'>
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt='' className='show' />
                </div>
                <h1>{val.courseName}</h1>
                <span>{val.course}</span>
              </div>
            ))}
          </div> */}


             <div className='grid'>
            {contentData.map((val) => (
              <div className='box' onClick={()=>{
                handleSubmit(val._id)
              }}>
                {/* <div className='img'>
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt='' className='show' />
                </div> */}
                <h1>{val.title}</h1>
                {/* <span>{val.course}</span> */}
              </div>
            ))}
          </div>
  <div className='container grid'>
          {/* <PriceCard /> */}
        </div>
          
        </div>
      </section>
}
    </>
  )
}

export default OnlineCourses
