import { RotatingLines } from "react-loader-spinner";
import "./loader.css"
function Loader() {
  return (
    // <RotatingLines
    //   strokeColor="grey"
    //   strokeWidth="5"
    //   animationDuration="0.75"
    //   width="96"
    //   visible={true}
    // />
<div className="Loaderparent">
    <div class="loader"></div>
    </div>
  )
}

export default Loader;